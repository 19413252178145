import "./App.css"

import { BrowserRouter as Router, Switch } from "react-router-dom"

// Pages
import Layout from "./pages/Layout"

function App() {
    return (
        <>
            <Router>
                <Switch>
                    <Layout />
                </Switch>
            </Router>
        </>
    )
}

export default App
