import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import "./index.css"
import "bootstrap/dist/css/bootstrap.min.css"
import {GlobalProvider} from './context/global'

ReactDOM.render(
  <GlobalProvider>
    <App />
  </GlobalProvider>,
  document.getElementById("root")
)
