import { useState, useEffect, useRef } from "react"
import Logger from "./Logger"
import { useGlobalContext } from "../../../context/global"

export default function TRXLogger({ LOCAL_STORAGE_NAME, setStage, collect, stage, getBalance, spread }) {
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState("")
  const [done, setDone] = useState(false)
  const [walletList] = useState(() => {
    const localStrData = localStorage.getItem(LOCAL_STORAGE_NAME)
    const walletList = JSON.parse(localStrData)
    walletList.splice(0, 1)
    if (localStrData) {
      const wallets = walletList.map((item) => {
        return { address: item.data[0], privateKey: item.data[1], amount: item.data[2] }
      })

      return wallets
    }

    return []
  })
  const { TRX_STAGES } = useGlobalContext()
  const LoggerDiv = useRef()

  // Append message
  useEffect(() => {
    if (message) {
      setMessages([...messages, message])
      const el = LoggerDiv.current
      el.scrollTop = el.scrollHeight
    }
  }, [message])

  useEffect(() => {
    // =======COLLECT [TRX]
    if (stage === TRX_STAGES.LOGGER) {
      async function collectTRX() {
        const collectings = Promise.all(
          walletList.map(async (wallet, index) => {
            return new Promise((resolve) =>
              setTimeout(async () => {
                const { wallet: validWallet, error } = await collect(wallet, setMessage)
                if (error) {
                  return resolve({ ...wallet, error })
                }

                return resolve(validWallet)
              }, index * 1500)
            )
          })
        )

        return collectings
      }
      // Save to localStorage
      collectTRX().then((result) => {
        localStorage.setItem(`${LOCAL_STORAGE_NAME}-result`, JSON.stringify(result))
        setTimeout(() => {
          setDone(true)
          setStage(TRX_STAGES.RESULT)
        }, 5000)
      })
    } 

    // =======SPREAD [TRX]
    if (stage === TRX_STAGES.SPREAD_LOGGER) {
      async function spreadTRX() {
        const spreadings = Promise.all(
          walletList.map(async (wallet, index) => {
            return new Promise((resolve) =>
              setTimeout(async () => {
                const { wallet: validWallet, error } = await spread(wallet, setMessage)
                if (error) {
                  return resolve({ ...wallet, error })
                }

                return resolve(validWallet)
              }, index * 1000)
            )
          })
        )

        return spreadings
      }
      // Save to localStorage
      spreadTRX().then((result) => {
        localStorage.setItem(`${LOCAL_STORAGE_NAME}-result`, JSON.stringify(result))
        setTimeout(() => {
          setDone(true)
          setStage(TRX_STAGES.RESULT)
        }, 5000)
      })
    } 

    // ======GET BALANCE [TRX]
    if(stage === TRX_STAGES.BALANCE) {
      async function getBalances() {
        const walletsWithBal = Promise.all(
          walletList.map(async (wallet, index) => {
            return new Promise((resolve) =>
              setTimeout(async () => {
                const { balanceTRX, error } = await getBalance(wallet.address)
                if (error ) {
                  setMessage(`${wallet.address} --- Failed to get balance`)
                  return resolve({ ...wallet, error })
                }
                // Done
                setMessage(`${wallet.address} --- ${balanceTRX} ~ TRX`)
                return resolve({ ...wallet, balanceTRX })
              }, index * 200)
            )
          })
        )
  
        return walletsWithBal
      }
  
      // Save to localStorage
      getBalances().then((result) => {
        localStorage.setItem(`${LOCAL_STORAGE_NAME}-balance`, JSON.stringify(result))
        setDone(true)
        setStage(TRX_STAGES.RESULT_BALANCE)
      })
    }
  }, [])

  return (
    <div style={{ overflowY: "scroll", maxHeight: "80vh" }} ref={LoggerDiv}>
      {!done && (
        <>
          <Logger messages={messages} />
          <div className="text-center">
            <div
              className="spinner-border"
              role="status"
              style={{ width: "3rem", height: "3rem" }}
            ></div>
          </div>
        </>
      )}
    </div>
  )
}
