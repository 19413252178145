import { Row, Col, Card } from "react-bootstrap"
import { useState, useEffect } from "react"
import { useGlobalContext } from "../context/global"
import { useEthereumContext } from "../context/ethereum"
import Loading from "../components/Loading"
import FileDrop from "../components/Stage/FileDrop/FileDrop"
import MainWallet from "../components/Stage/Wallet/MainWallet"
import SpreadWallet from "../components/Stage/Wallet/SpreadWallet"
import ERC20Table from "../components/Stage/DataTable/ERC20Table"
import ERC20Logger from "../components/Stage/Logger/ERC20Logger"

import BigNumber from "bignumber.js"
const LOCAL_STORAGE_NAME = "ERC20-Wallets"

export default function ERC20() {
  const { isLoading, setIsLoading, currentPath, ERC20_STAGES } = useGlobalContext()
  const [recipientAddr, setRecipientAddr] = useState("")
  const [spreaderAddr, setSpreaderAddr] = useState("")
  const [spreaderPK, setSpreaderPK] = useState("")
  const [stage, setStage] = useState(ERC20_STAGES.DROP_FILE)
  const [token, setToken] = useState()
  const [amountToSpread, setAmountToSpread] = useState(0)
  const {
    web3,
    getBalanceERC20,
    getBalanceETH,
    checkTransferERC20,
    sendERC20,
    checkAddresses,
    convertToToken,
    convertToDecimals,
    spreadERC20
  } = useEthereumContext()

  useEffect(() => {
    setIsLoading(false)
    const walletList = localStorage.getItem(LOCAL_STORAGE_NAME)
    if (walletList) {
      setStage(ERC20_STAGES.DATA_TABLE)
    } else {
      setStage(ERC20_STAGES.DROP_FILE)
    }
  }, [])

  // Validate recipient wallet (main wallet)
  const checkMainWallet = (address) => {
    address = address.toString().trim()
    if (web3.utils.isAddress(address)) {
      return [true, null]
    }

    return [null, false]
  }

  // Validation clone wallet's data from file
  const validation = async function (wallet) {
    try {
      // Trim data
      wallet.address = wallet.address.toString().trim()
      wallet.privateKey = wallet.privateKey.toString().trim()

      // Check address
      const { error: addressError } = await checkAddresses(wallet.address, recipientAddr)
      if (addressError) {
        return { ...wallet, error: addressError }
      }

      // Get balanceETH
      const { balanceETH, error: balanceETHErr } = await getBalanceETH(wallet.address)
      if (balanceETHErr) {
        return { ...wallet, error: balanceETHErr }
      }
      wallet.balanceETH = balanceETH

      // Get balanceERC20
      const { balanceERC20, error: getBLError, decimals } = await getBalanceERC20(
        wallet.address,
        token
      )
      if (getBLError) {
        return { ...wallet, error: getBLError }
      }
  
      // Convert amount ("" => balanceERC20)
      if (!wallet.amount) {
        wallet.amount = (convertToToken(parseInt(balanceERC20), decimals)).convertAmount
        wallet.amountERC20 = balanceERC20
      } else {
        wallet.amountERC20 = (convertToDecimals(parseInt(wallet.amount), decimals)).convertAmount
      }

      // check transfer ERC20
      const { error: transferErr, gasFee } = await checkTransferERC20(
        wallet, recipientAddr, wallet.amountERC20, token
      )
      if (transferErr) {
        return { validWallet: {...wallet}, error: transferErr, gasFee}
      }

      return {
        validWallet: {
          ...wallet,
          balanceETH,
          balanceERC20,
          gasFee,
        },
      }
    } catch (error) {
      return { ...wallet, error: error.message }
    }
  }

  // Get balance in decimals
  const getBalance = async function (address) {
    try {
      address = address.toString().trim().toLowerCase()
      const { balanceERC20, decimals, error } = await getBalanceERC20(address, token)
      
      if (error) {
        return { error }
      }

      // let balance = new BigNumber(balanceERC20)
      let balance = BigNumber(balanceERC20).dividedBy(parseInt(decimals))

      return { balanceERC20: balance }
    } catch (error) {
      console.log(error.message)
      return { error }
    }
  }

  // Collect ERC20
  const collect = async (wallet, setMessage) => {
    try {
      const { validWallet, error: transferErr, gasFee} = await validation(wallet)

      // transfer Error
      if (transferErr ) {
        setMessage(`Collecting from ${wallet.address}... ======> Error: [${transferErr}]`)
        return { ...validWallet, error: transferErr, gasFee }
      }

      const { txHash, error: txHashError } = await sendERC20(
        validWallet,
        recipientAddr,
        validWallet.amountERC20,
        token
      )
      if (txHashError) {
        setMessage(`Collecting from ${wallet.address}... ======> Error: [${txHashError}]`)
      } else {
        setMessage(
          `Collecting from ${wallet.address}... ======> DONE [Hash: ${txHash}]`
        )
      }


      return {
        ...validWallet, error: txHashError, txHash
      }
    } catch (error) {
      return { ...wallet, error: error.message }
    }
  }

  // Validate provider wallet
  const checkSpreaderWallet = async function () {
    try {
      // Check address
      const isValidAddress = web3.utils.isAddress(spreaderAddr)
      if (!isValidAddress) {
        return [null, `Invalid format [${spreaderAddr}]`]
      }

      // get balanceETH
      const { balanceETH, error: balETHError } = await getBalanceETH(spreaderAddr)
      if (balETHError) {
        return [null, balETHError]
      }

      // Check empty balanceETH
      if (parseInt(balanceETH) === 0) {
        return [null, `Empty balance (ETH)`]
      }

      // Get balanceERC20
      const { balanceERC20, error: balERC20Error } = await getBalanceERC20(spreaderAddr)
      if (balERC20Error) {
        return [null, balETHError]
      }

      // Check empty balanceERC20 
      if (parseInt(balanceERC20) === 0) {
        return [null, `Empty balance [${token.symbol}]`]
      }

      // Check balanceERC20 > amountToSpread
      // Make sure balance is sufficient for 1 spread
      if (parseFloat(amountToSpread) > parseInt(balanceERC20)){
        return [null, `Amount exceeds balance - Balance ${web3.utils.fromWei(balanceERC20.toString(), 'ether')}`]
      }

      return [true, null]
    } catch (error) {
      return [null, error.message ? error.message : error]
    }
  }
  
  // Validate spreader each spread
  const validateSpreader = async function(toAddress) {
    try {
      // Check address format
      const isValidFormat = web3.utils.isAddress(toAddress)
      if (!isValidFormat) {
        return {error: `Invalid format [${toAddress}]`}
      }

      // Check same address
      if(toAddress === spreaderAddr){
        return {error: `Same address [${toAddress}]`}
      }

      // Get balance ETH
      const {balanceETH, error: balETH_ERR} = await getBalanceETH(spreaderAddr)
      if (balETH_ERR) {
        return {error: balETH_ERR}
      }

      // Check empty balanceETH
      if (parseInt(balanceETH) === 0 ) {
        return {error: `Empty balance (ETH)`}
      }

      // Get balance ERC20
      const {balanceERC20, error: balERC20_Err, decimals} = await getBalanceERC20(spreaderAddr, token)
      if(balERC20_Err) {
        return {error: balERC20_Err}
      }

      // Check empty balance ERC20
      if (parseInt(balanceERC20) === 0) {
        return {error: `Empty balance ${token.symbol}`}
      }

      // Convert
      const convertSpreadAmount = convertToDecimals(parseFloat(amountToSpread), decimals).convertAmount
      // Check insufficient ERC20
      if (parseInt(balanceERC20) < parseInt(convertSpreadAmount)) {
        return {error: `Insufficient balance ${token.symbol}`}
      }
      
      return {isValid: true, convertSpreadAmount}
    } catch (error) {
      return {error: error.message}
    }
  }

  // Spread ERC20
  const spread = async function (wallet, setMessage, index) {
    try {
      const { error: transferErr, convertSpreadAmount } = await validateSpreader(wallet.address)
      wallet.amount = parseInt(amountToSpread)
      // transfer Error
      if (transferErr) {
        setMessage(`Spreading to ${wallet.address}... ======> Error: [${transferErr}]`)
        return { ...wallet, error: transferErr }
      }
      
      // Spreading TRC20 to sub wallets
      const { txHash, error: txHashError } = await spreadERC20(
        {address: spreaderAddr, privateKey: spreaderPK},
        wallet.address,
        parseInt(convertSpreadAmount),
        token,
        index
      )
      if (txHashError) {
        setMessage(`Spreading to ${wallet.address}... ======> Error: [${txHashError}]`)
      }

      if (txHash) {
        setMessage(`Spreading to ${wallet.address}... ======> DONE [Hash: ${txHash}]`)
      }
      
      return { ...wallet, error: txHashError, txHash, amount: amountToSpread }
    } catch (error) {
      return {...wallet, error: error.message}
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className="px-1 mx-3">
        <Row className="my-3">
          <Col>
            <Card>
              <h5 className="card-header">
                Đồng <strong>{currentPath.toUpperCase()}</strong>
              </h5>
              <div className="card-body p-3">
                {/* Stage 1 */}
                {stage === ERC20_STAGES.DROP_FILE && (
                  <FileDrop
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    STAGES={ERC20_STAGES}
                    setStage={setStage}
                  />
                )}

                {/* Stage 2 || 5 || 7 */}
                {(stage === ERC20_STAGES.DATA_TABLE ||
                  stage === ERC20_STAGES.RESULT ||
                  stage === ERC20_STAGES.RESULT_BALANCE) && (
                  <ERC20Table
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    setStage={setStage}
                    stage={stage}
                    token={token}
                    setToken={setToken}
                  />
                )}

                {/* Stage 3 */}
                {stage === ERC20_STAGES.TRANSACTION_WALLET && (
                  <MainWallet
                    setStage={setStage}
                    recipientAddr={recipientAddr}
                    setRecipientAddr={setRecipientAddr}
                    checkMainWallet={checkMainWallet}
                    STAGES={ERC20_STAGES}
                  />
                )}

                {/* Stage 3 */}
                {stage === ERC20_STAGES.SPREAD_WALLET && (
                  <SpreadWallet
                      token={token}
                      setStage={setStage}
                      STAGES={ERC20_STAGES}
                      spreaderAddr={spreaderAddr}
                      setSpreaderAddr={setSpreaderAddr}
                      spreaderPK={spreaderPK}
                      setSpreaderPK={setSpreaderPK}
                      validateSpreadWallet={checkSpreaderWallet}
                      amountToSpread={amountToSpread}
                      setAmountToSpread={setAmountToSpread}
                      nextStage={ERC20_STAGES.SPREAD_LOGGER}
                      prevStage={ERC20_STAGES.DATA_TABLE}
                  />
                )}

                {/* Stage 4 || 6 */}
                {(stage === ERC20_STAGES.LOGGER || stage === ERC20_STAGES.BALANCE|| stage === ERC20_STAGES.SPREAD_LOGGER) && (
                  <ERC20Logger
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    stage={stage}
                    setStage={setStage}
                    token={token}
                    collect={collect}
                    spread={spread}
                    getBalance={getBalance}
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
