export const File_Columns = [
  {
    label: "Address",
    field: "address",
    sort: "asc",
    width: 150,
  },
  {
    label: "Private Key",
    field: "privateKey",
    sort: "asc",
    width: 270,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 100,
  },
]
export const Balance_Columns = [
  {
    label: "Address",
    field: "address",
    sort: "asc",
    width: 150,
  },
  {
    label: "Amount (ERC20)",
    field: "amount",
    sort: "asc",
    width: 150,
  },
  {
    label: "Balance (ERC20)",
    field: "balanceERC20",
    sort: "asc",
    width: 150,
  },
]
export const Result_Columns = [
  {
    label: "Address",
    field: "address",
    sort: "asc",
    width: 150,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "asc",
    width: 100,
  },
  {
    label: "Error",
    field: "error",
    sort: "asc",
    width: 200,
  },
  {
    label: "Hash",
    field: "txHash",
    sort: "asc",
    width: 250,
  },
]
