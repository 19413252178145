import { Row, Col, Card } from "react-bootstrap"
import { useState, useEffect } from "react"
import { useGlobalContext } from "../context/global"
import { useEthereumContext } from "../context/ethereum"

import Loading from "../components/Loading"
import EtherWalletsTable from "../components/Stage/DataTable/EtherWalletsTable"

import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormGroup from "react-bootstrap/FormGroup"
import FormLabel from "react-bootstrap/FormLabel"
import FormControl from "react-bootstrap/FormControl"

const LOCAL_STORAGE_NAME = "ETHER-Wallets"

export default function EtherWallets() {
  const { isLoading, setIsLoading, WALLETS_STAGES } = useGlobalContext()
  const {
    createAccounts
  } = useEthereumContext()
  const [stage, setStage] = useState(WALLETS_STAGES.GENERATE)
  const [quantity, setQuantity] = useState(0)
  const [token, setToken] = useState()

  useEffect(() => {
    setIsLoading(false)
    const walletList = localStorage.getItem(LOCAL_STORAGE_NAME)
    // Imported file
    if (walletList) {
      setStage(WALLETS_STAGES.DATA_TABLE)
      return
    }
    
    setStage(WALLETS_STAGES.GENERATE)
  }, [])

  // Generate accounts
  const handleGenerate = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const {wallets} = createAccounts(quantity)
    localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(wallets))
    setIsLoading(false)
    setStage(WALLETS_STAGES.DATA_TABLE)
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className="px-1 mx-3">
        <Row className="my-3">
          <Col>
            <Card>
              <h5 className="card-header">Ví Ethereum</h5>
              <div className="card-body p-3">
                {/* Quantity */}
                {/* Stage 1 */}
                {stage === WALLETS_STAGES.GENERATE && (
                  <>
                    <Form onSubmit={handleGenerate} inline className="mb-2 p-2">
                      <FormGroup>
                        <FormLabel className="mr-2">Số lượng:</FormLabel>
                        <FormControl
                          className="mr-2"
                          name="quantity"
                          type="number"
                          onChange={(e) => setQuantity(parseInt(e.target.value))}
                          value={quantity}
                          min={0}
                        />
                        <Button variant="primary" type="submit">
                          Generate
                        </Button>
                      </FormGroup>
                    </Form>
                  </>
                )}

                {/* Stage 2 || 5 || 7 */}
                {(stage === WALLETS_STAGES.DATA_TABLE) && (
                  <EtherWalletsTable
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    setStage={setStage}
                    stage={stage}
                    token={token}
                    setToken={setToken}
                  />
                )}

              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
