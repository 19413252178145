import { CSVDownloader, jsonToCSV } from "react-papaparse"
import { useState, useEffect } from "react"
import { FaFileDownload, FaTimes, FaWallet, FaStackOverflow,FaMenorah } from "react-icons/fa"
import Table from "../../Table/Table"
import { useGlobalContext } from "../../../context/global"
import {Balance_Columns, Result_Columns, File_Columns} from '../Columns/ETHCol'

export default function ETHTable({ LOCAL_STORAGE_NAME, stage, setStage }) {
  const { validateInputCSV, ETH_STAGES, parseResultDownload } = useGlobalContext()
  const [error, setError] = useState("")
  const [showResult, setShowResult] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [walletList, setWalletList] = useState([])
  const [dataDownload, setDataDownload] = useState("[]")

  useEffect(() => {
    if (stage === ETH_STAGES.RESULT) {
      setShowInput(true)
      setShowResult(false)
    } else if (stage === ETH_STAGES.DATA_TABLE) {
      setShowInput(false)
      setShowResult(true)
    }
  }, [])

  // Set data for table
  useEffect(() => {
    if (stage === ETH_STAGES.DATA_TABLE) {
      const walletData = validateInputCSV(LOCAL_STORAGE_NAME, setError)
      if (walletData.length !== 0) {
        setWalletList(walletData)
        setDataDownload(walletData)
        return
      }
    } else if (stage === ETH_STAGES.RESULT) {
      const localStrData = localStorage.getItem(`${LOCAL_STORAGE_NAME}-result`)
      if (localStrData) {
        setWalletList(JSON.parse(localStrData))
        setDataDownload(parseResultDownload(JSON.parse(localStrData)))
        return
      }
    } else if (stage === ETH_STAGES.RESULT_BALANCE) {
      const localStrData = localStorage.getItem(`${LOCAL_STORAGE_NAME}-balance`)
      if (localStrData) {
        setWalletList(JSON.parse(localStrData))
        setDataDownload(JSON.parse(localStrData))
        return
      }
    }
    return setWalletList([])
  }, [stage])

  const handleOnRemoveFile = (data) => {
    localStorage.removeItem(LOCAL_STORAGE_NAME)
    localStorage.removeItem(`${LOCAL_STORAGE_NAME}-result`)
    setWalletList([])
    setStage(ETH_STAGES.DROP_FILE)
  }

  return (
    <>
      {error && <p className="text-danger">{error}</p>}
      <div className="d-flex justify-content-between">
        <div className="left-button">
          {/* Balance */}
          <button
            className="btn btn-info mr-2"
            onClick={(e) => {
              setStage(ETH_STAGES.BALANCE)
              setShowResult(false)
              setShowInput(true)
            }}
          >
            <FaWallet className="mr-1" /> Balance
          </button>
          {/* Collect */}
          <button
            className="btn btn-success  mr-2"
            onClick={(e) => setStage(ETH_STAGES.TRANSACTION_WALLET)}
          >
            <FaStackOverflow /> Gom
          </button>
          {/* Spread */}
          <button className="btn btn-warning mr-2" onClick={(e) => setStage(ETH_STAGES.SPREAD_WALLET)}>
            <FaMenorah /> Rải
          </button>
          {showResult && (
            <button
              className="btn btn-warning mr-2"
              onClick={(e) => {
                setStage(ETH_STAGES.RESULT)
                setShowResult(false)
                setShowInput(true)
              }}
            >
              <FaWallet className="mr-1" /> Kết quả trước
            </button>
          )}
          {showInput && (
            <button
              className="btn btn-warning mr-2"
              onClick={(e) => {
                setStage(ETH_STAGES.DATA_TABLE)
                setShowResult(true)
                setShowInput(false)
              }}
            >
              <FaWallet className="mr-1" /> Danh sách CSV
            </button>
          )}
        </div>

        <div className="right-button">
          {/* Downloader */}
          <button className="btn btn-primary" title="Tải về">
            <CSVDownloader data={jsonToCSV(dataDownload)} filename="danhsachvi">
              <FaFileDownload />
            </CSVDownloader>
          </button>
          {/* Delete */}
          <button
            className="btn btn-danger text-white ml-2 self-align-end"
            onClick={handleOnRemoveFile}
            title="Xoá file"
          >
            <FaTimes />
          </button>
        </div>
      </div>

      <hr className="my-3" />

      <div className="datatable-container" style={{ overflowX: "scroll", maxWidth: "100%", fontSize: "0.85rem" }}>
        {stage === ETH_STAGES.RESULT_BALANCE && <Table columns={Balance_Columns} rows={walletList} />}
        {stage === ETH_STAGES.DATA_TABLE && <Table columns={File_Columns} rows={walletList} />}
        {stage === ETH_STAGES.RESULT && <Table columns={Result_Columns} rows={walletList} />}
      </div>
    </>
  )
}
