import { useContext, createContext, useState, useEffect } from "react"
import { useRootStore } from "./mst/Root"

const GlobalContext = createContext()
const ROUTES = ["eth", "erc20", "trx", "trc20", "bsc", "balance", "wallets"]
const BALANCE_STAGES = Object.freeze({ DROP_FILE: 1, DATA_TABLE: 2, LOGGER: 3, RESULT: 4 })
const ETH_STAGES = Object.freeze({
  DROP_FILE: 1,
  DATA_TABLE: 2,
  TRANSACTION_WALLET: 3,
  LOGGER: 4,
  RESULT: 5,
  BALANCE: 6,
  RESULT_BALANCE: 7,
  SPREAD_WALLET: 8,
  SPREAD_LOGGER: 9,
})
const ERC20_STAGES = {
  DROP_FILE: 1,
  DATA_TABLE: 2,
  TRANSACTION_WALLET: 3,
  LOGGER: 4,
  RESULT: 5,
  BALANCE: 6,
  RESULT_BALANCE: 7,
  SPREAD_WALLET: 8,
  SPREAD_LOGGER: 9,
}
const TRX_STAGES = Object.freeze({
  DROP_FILE: 1,
  DATA_TABLE: 2,
  TRANSACTION_WALLET: 3,
  LOGGER: 4,
  RESULT: 5,
  BALANCE: 6,
  RESULT_BALANCE: 7,
  SPREAD_WALLET: 8,
  SPREAD_LOGGER: 9,
})
const TRC20_STAGES = {
  DROP_FILE: 1,
  DATA_TABLE: 2,
  TRANSACTION_WALLET: 3,
  LOGGER: 4,
  RESULT: 5,
  BALANCE: 6,
  RESULT_BALANCE: 7,
  SPREAD_WALLET: 8,
  SPREAD_LOGGER: 9,
  RANDOM_SPREAD_WALLET: 10,
  RANDOM_SPREAD_LOGGER: 11,
}
const WALLETS_STAGES = Object.freeze({
  DROP_FILE: 1,
  GENERATE: 2,
  DATA_TABLE: 3,
  ACTIVATE: 4,
  ACTIVATE_WALLET: 5,
  LOGGER: 6,
  RESULT: 7,
})

export const GlobalProvider = function ({ children }) {
  const [isLoading, setIsLoading] = useState(true)
  const [currentPath, setCurrentPath] = useState("")
  const [fileData, setFileData] = useState([])
  const { RootStore } = useRootStore()

  useEffect(() => {
    let URLPath = window.location.pathname.split("/")[1]
    URLPath = URLPath ? URLPath.toLowerCase() : URLPath

    if (!URLPath) {
      setCurrentPath("")
    } else if (ROUTES.includes(URLPath)) {
      setCurrentPath(URLPath)
    }
  }, [])

  // Validate input csv for collecting
  const validateInputCSV = (LOCAL_STORAGE_NAME, setError) => {
    const localStrData = localStorage.getItem(LOCAL_STORAGE_NAME)
    if (localStrData) {
      const dataArray = JSON.parse(localStrData)
      const header = dataArray.splice(0, 1)

      if (header[0].data.length !== 3) {
        setError("Danh sách chỉ cần 3 cột [address, privateKey, amount]")
        return []
      }

      if (
        header[0].data[0] !== "address" &&
        header[0].data[1] !== "privateKey" &&
        header[0].data[2] !== "amount"
      ) {
        setError("Vui lòng khai báo tên 3 cột [address, privateKey, amount] ở dòng đầu tiên.")
        return []
      }

      if (
        header[0].data[0] !== "address" ||
        header[0].data[1] !== "privateKey" ||
        header[0].data[2] !== "amount"
      ) {
        setError("Vui lòng sắp xếp cột theo thứ tự [address, privateKey, amount]")
        return []
      }

      const wallets = dataArray.map((item) => {
        return { address: item.data[0], privateKey: item.data[1], amount: item.data[2] }
      })

      return wallets
    }

    return []
  }

  // Validate account list [address, privateKey]
  const validateAccList = (LOCAL_STORAGE_NAME, setError) => {
    const localStrData = localStorage.getItem(LOCAL_STORAGE_NAME)

    if (localStrData) {
      const dataArray = JSON.parse(localStrData)
      const header = dataArray.splice(0, 1)

      if (header[0].data.length !== 2) {
        setError("Danh sách chỉ cần 2 cột [address, privateKey]")
        return []
      }

      if (header[0].data[0] !== "address" && header[0].data[1] !== "privateKey") {
        setError("Vui lòng khai báo tên 2 cột [address, privateKey] ở dòng đầu tiên.")
        return []
      }

      if (header[0].data[0] !== "address" || header[0].data[1] !== "privateKey") {
        setError("Vui lòng sắp xếp cột theo thứ tự [address, privateKey]")
        return []
      }

      const wallets = dataArray.map((item) => {
        return { address: item.data[0], privateKey: item.data[1] }
      })

      console.log("wallets", wallets)
      return wallets
    }

    return []
  }

  const parseResultDownload = (jsonData) => {
    const downloadData = jsonData.map((item) => {
      return {
        address: item.address,
        amount: item.amount,
        error: item.error,
        hash: item.txHash,
      }
    })

    return downloadData
  }

  return (
    <GlobalContext.Provider
      value={{
        RootStore,
        ROUTES,
        isLoading,
        setIsLoading,
        currentPath,
        setCurrentPath,
        fileData,
        setFileData,
        validateInputCSV,
        validateAccList,
        BALANCE_STAGES,
        ETH_STAGES,
        ERC20_STAGES,
        TRX_STAGES,
        TRC20_STAGES,
        WALLETS_STAGES,
        parseResultDownload,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = function () {
  return useContext(GlobalContext)
}
