import { CSVDownloader } from "react-papaparse"
import { useState, useEffect } from "react"
import { FaFileDownload, FaTimes, FaWallet } from "react-icons/fa"
import Table from "../../Table/Table"
import { useGlobalContext } from "../../../context/global"
const columns = [
  {
    label: "Address",
    field: "address",
    sort: "asc",
    width: 150,
  },
  {
    label: "Balance",
    field: "balance",
    sort: "asc",
    width: 150,
  },
  {
    label: "Unit",
    field: "unit",
    sort: "asc",
    width: 50,
  },
]

export default function BalanceTable({ LOCAL_STORAGE_NAME, stage, setStage }) {
  const { BALANCE_STAGES, validateInputCSV } = useGlobalContext()
  const [showResult, setShowResult] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [error, setError] = useState("")
  const [walletList, setWalletList] = useState([])

  useEffect(() => {
    if(stage === BALANCE_STAGES.RESULT) {
      setShowInput(true)
      setShowResult(false)
    }else if (stage === BALANCE_STAGES.DATA_TABLE){
      setShowInput(false)
      setShowResult(true)
    }
  }, [])

  // Set data for table
  useEffect(() => {
    if (stage === BALANCE_STAGES.DATA_TABLE) {
      const walletData = validateInputCSV(LOCAL_STORAGE_NAME, setError)
      if (walletData.length !== 0) {
        return setWalletList(walletData)
      }
      return setWalletList([])
    } else if (stage === BALANCE_STAGES.RESULT) {
      const localStrData = localStorage.getItem(`${LOCAL_STORAGE_NAME}-result`)
      if (localStrData) {
        return setWalletList(JSON.parse(localStrData))
      }
    }
  }, [stage])

  const handleOnRemoveFile = (data) => {
    localStorage.removeItem(LOCAL_STORAGE_NAME)
    localStorage.removeItem(`${LOCAL_STORAGE_NAME}-result`)
    setWalletList([])
    setStage(BALANCE_STAGES.DROP_FILE)
  }

  return (
    <>
      {error && <p className="text-danger">{error}</p>}

      <div className="d-flex justify-content-between">
        <div className="left-button">
          <button className="btn btn-info mr-2" 
            onClick={(e) => {
              setStage(BALANCE_STAGES.LOGGER)
              setShowResult(false)
              setShowInput(true)
            }}>
            <FaWallet className="mr-1" /> Balance
          </button>
          {showResult && (
            <button
              className="btn btn-warning mr-2"
              onClick={(e) => {
                setStage(BALANCE_STAGES.RESULT)
                setShowResult(false)
                setShowInput(true)
              }}
            >
              <FaWallet className="mr-1" /> Kết quả trước
            </button>
          )}
          {showInput && (
            <button
              className="btn btn-warning mr-2"
              onClick={(e) => {
                setStage(BALANCE_STAGES.DATA_TABLE)
                setShowResult(true)
                setShowInput(false)
              }}
            >
              <FaWallet className="mr-1" /> Danh sách CSV
            </button>
          )}
        </div>

        <div className="right-button">
          {/* Downloader */}
          <button className="btn btn-primary" title="Tải về">
            <CSVDownloader data={walletList} filename="danhsachvi">
              <FaFileDownload />
            </CSVDownloader>
          </button>
          {/* Delete */}
          <button
            className="btn btn-danger text-white ml-2 self-align-end"
            onClick={handleOnRemoveFile}
            title="Xoá file"
          >
            <FaTimes />
          </button>
        </div>
      </div>

      <hr className="my-3" />

      <div className="datatable-container" style={{ overflowX: "scroll", maxWidth: "100%", fontSize: "0.85rem" }}>
        <Table columns={columns} rows={walletList} />
      </div>
    </>
  )
}
