import { Row, Col, Card } from "react-bootstrap"
import { useState, useEffect } from "react"
import { useGlobalContext } from "../context/global"
import { useTronContext } from "../context/tron"

import Loading from "../components/Loading"
import ActivateWallet from "../components/Stage/Wallet/ActivateWallet"
import TronWalletsTable from "../components/Stage/DataTable/TronWalletsTable"
import ActivateLogger from "../components/Stage/Logger/ActivateLogger"

import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormGroup from "react-bootstrap/FormGroup"
import FormLabel from "react-bootstrap/FormLabel"
import FormControl from "react-bootstrap/FormControl"

const LOCAL_STORAGE_NAME = "TRON-Wallets"

export default function TronWallets() {
  const { isLoading, setIsLoading, WALLETS_STAGES } = useGlobalContext()
  const {
    getBalanceTRX,
    sendTRX,
    tronWeb,
    createAccounts,
    isActive,
  } = useTronContext()
  const [stage, setStage] = useState(WALLETS_STAGES.GENERATE)
  const [activateWalletAddr, setActivateWalletAddr] = useState("")
  const [activateWalletPK, setActivateWalletPK] = useState("")
  const [quantity, setQuantity] = useState(0)
  const [token, setToken] = useState()

  useEffect(() => {
    setIsLoading(false)
    const walletList = localStorage.getItem(LOCAL_STORAGE_NAME)
    // Imported file
    if (walletList) {
      setStage(WALLETS_STAGES.DATA_TABLE)
      return
    }
    

    setStage(WALLETS_STAGES.GENERATE)
  }, [])

  // Validation spread wallet
  const validateActivationWallet = async function () {
    try {
      // Check address format
      const isValidFormat = tronWeb.isAddress(activateWalletAddr)
      if (!isValidFormat) {
        return { error: `Invalid format [${activateWalletAddr}]` }
      }

      // get balanceTRX
      const { balanceTRX, error: balTRXError } = await getBalanceTRX(activateWalletAddr)
      if (balTRXError) {
        return { error: balTRXError }
      }

      // check balanceTRX ( !== 0 )
      if (parseInt(balanceTRX) === 0) {
        return { error: `Empty balance (TRX)` }
      }

      const requiredTRX = 0.11
      // Make sure balance is sufficient for 1 activation
      if (parseInt(balanceTRX) < tronWeb.toSun(requiredTRX)) {
        return { error: `Insufficient balance (TRX) - Required: at least ${requiredTRX} (TRX) ` }
      }

      return { isValid: true }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Validate each activation
  const validateActivation = async function (toAddress) {
    try {
      toAddress = toAddress.toString().trim()
      // Check format toAddress
      const isValidToAddress = tronWeb.isAddress(toAddress)
      if (!isValidToAddress) {
        return {error: `Invalid format [${toAddress}]`}
      }

      // Check same address
      if(toAddress === activateWalletAddr){
        return {error: `Same address [${activateWalletAddr}]`}
      }

      // Get balanceTRX
      const { balanceTRX, error: balTRXError } = await getBalanceTRX(activateWalletAddr)
      if (balTRXError) {
        return { error: balTRXError }
      }

      // Check balanceTRX ( !== 0 )
      if (parseInt(balanceTRX) === 0) {
        return { error: `Empty balance (TRX)` }
      }

      const requiredTRX = 0.11
      // Make sure balance is sufficient for 1 activation
      if (parseInt(balanceTRX) < tronWeb.toSun(requiredTRX)) {
        return { error: `Insufficient balance (TRX) - Required: at least ${requiredTRX} (TRX) ` }
      }

      return { isValid: true }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Activate wallets
  const activate = async function (wallet, setMessage) {
    // Trim data
    wallet.address = wallet.address.toString().trim()

    // Validate activateWallet
    const {error: activateWalletError} = await validateActivation(wallet.address)
    if(activateWalletError) {
      return {...wallet, error: activateWalletError}
    }

    // Check active accounts
    const active = await isActive(wallet.address)
    if (active) {
      setMessage(`Activating 
      ${wallet.address}... ======> Error: Already active`)
      // return { ...wallet, active: "TRUE", error: "Already active" }
    } 
    const { txHash, error: txHashError } = await sendTRX(
      { address: activateWalletAddr, privateKey: activateWalletPK },
      wallet.address,
      1 // 1 SUN
    )

    if (txHashError && !active) {
      setMessage(`Activating 
      ${wallet.address}... ======> Error: ${txHashError}`)
    }
    if (txHash && !active) {
      setMessage(`Activating ${wallet.address}... ======> DONE [${txHash}] `)
    }

    return {
      ...wallet,
      active: txHashError ? "FALSE" : "TRUE",
    }
  }

  // Generate accounts
  const handleGenerate = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    createAccounts(quantity).then((list) => {
      localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(list))
      setIsLoading(false)
      setStage(WALLETS_STAGES.DATA_TABLE)
    })
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className="px-1 mx-3">
        <Row className="my-3">
          <Col>
            <Card>
              <h5 className="card-header">Ví TRON</h5>
              <div className="card-body p-3">
                {/* Quantity */}
                {/* Stage 1 */}
                {stage === WALLETS_STAGES.GENERATE && (
                  <>
                    <Form onSubmit={handleGenerate} inline className="mb-2 p-2">
                      <FormGroup>
                        <FormLabel className="mr-2">Số lượng:</FormLabel>
                        <FormControl
                          className="mr-2"
                          name="quantity"
                          type="number"
                          onChange={(e) => setQuantity(parseInt(e.target.value))}
                          value={quantity}
                          min={0}
                        />
                        <Button variant="primary" type="submit">
                          Generate
                        </Button>
                      </FormGroup>
                    </Form>
                  </>
                )}

                {/* Stage 2 || 5 || 7 */}
                {(stage === WALLETS_STAGES.DATA_TABLE ||
                  stage === WALLETS_STAGES.RESULT) && (
                  <TronWalletsTable
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    setStage={setStage}
                    stage={stage}
                    isActive={isActive}
                    token={token}
                    setToken={setToken}
                  />
                )}

                {/* Stage 3  */}
                {stage === WALLETS_STAGES.ACTIVATE_WALLET && (
                  <ActivateWallet
                    activateWalletAddr={activateWalletAddr}
                    setActivateWalletAddr={setActivateWalletAddr}
                    activateWalletPK={activateWalletPK}
                    setActivateWalletPK={setActivateWalletPK}
                    setStage={setStage}
                    validateActivationWallet={validateActivationWallet}
                    token={token}
                    setToken={setToken}
                  />
                )}

                {/* Stage 4 */}
                {(stage === WALLETS_STAGES.LOGGER ||
                  stage === WALLETS_STAGES.ACTIVATE) && (
                  <ActivateLogger
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    setStage={setStage}
                    stage={stage}
                    activate={activate}
                    token={token}
                    setToken={setToken}
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
