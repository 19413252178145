// TOKEN ABI
import { USDT_ABI } from "./ethereum_erc20/USDT"
import { WEENUS_ABI } from "./ethereum_erc20/WEENUS"

const INFURA_KEYS = [
  "https://mainnet.infura.io/v3/aae71ad4abb14859a9a7b91c34f8839e",
  "https://mainnet.infura.io/v3/11c7c2da0a2a4b4c8d1d986b4b3c5f90",
]

// Ethereum
// export const WEB3_MAINNET_RPC = 'https://mainnet.infura.io/v3/aae71ad4abb14859a9a7b91c34f8839e'
export const WEB3_MAINNET_RPC = INFURA_KEYS[1]
export const WEB3_ROPSTEN_RPC = "https://ropsten.infura.io/v3/aae71ad4abb14859a9a7b91c34f8839e"
export const MAINNET_SCAN_URL = "https://etherscan.io/tx/"
export const TESTNET_SCAN_URL = "https://ropsten.etherscan.io/tx/"

// TOKEN ERC20
export const TOKENS = [
  {
    symbol: "USDT",
    address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    ABI: USDT_ABI,
  },
  {
    symbol: "WEENUS",
    address: "0x101848d5c5bbca18e6b4431eedf6b95e9adf82fa",
    ABI: WEENUS_ABI,
  },
]
