import { MDBDataTable } from "mdbreact"

const Table = ({ columns, rows }) => {
  return (
    <>
      <MDBDataTable
        striped
        hover
        bordered
        data={{
          columns: columns,
          rows: rows,
        }}
      />
    </>
  )
}

export default Table
