import { Row, Col, Card } from 'react-bootstrap'
import { useState, useEffect, useRef } from 'react'
import { useGlobalContext } from '../context/global'
import { useRootStore } from '../context/mst/Root'
import Loading from '../components/Loading'
import FileDrop from '../components/Stage/FileDrop/FileDrop'
import MainWallet from '../components/Stage/Wallet/MainWallet'
import SpreadWallet from '../components/Stage/Wallet/SpreadWallet'
import BscTable from '../components/Stage/DataTable/BscTable'
import BscLogger from '../components/Stage/Logger/BscLogger'

import BigNumber from 'bignumber.js'
import { BSC_TOKENS } from '../constant/bsc'

// STAGES
// const LOCAL_STORAGE_NAME = "Bep20-Wallets"

export default function BSC() {
	const { isLoading, setIsLoading } = useGlobalContext()
	const { RootStore } = useRootStore()
	const {
		web3,
		LOCAL_STORAGE_NAME,
		STAGES,
		getBalanceBep20,
		getBalanceBnb,
		convertToToken,
		convertToDecimals,
		sendBep20,
		sendBnb,
		checkAddressForTransferring,
		checkAddress,
		getWalletFromPK,
		calculateBEP20Fee,
	} = RootStore.BscStore
	const [recipientAddr, setRecipientAddr] = useState('')
	const [spreaderAddr, setSpreaderAddr] = useState('')
	const [spreaderPK, setSpreaderPK] = useState('')
	const [token, setToken] = useState(BSC_TOKENS[0])
	const [amountToSpread, setAmountToSpread] = useState(0)
	const [stage, setStage] = useState(STAGES.DROP_FILE)

	useEffect(() => {
		setIsLoading(false)
		const walletList = localStorage.getItem(LOCAL_STORAGE_NAME)
		if (walletList) {
			setStage(STAGES.DATA_TABLE)
		} else {
			setStage(STAGES.DROP_FILE)
		}
	}, [])

	// Validate recipient wallet (main wallet)
	const checkRecipientWallet = (_address) => {
		try {
			const [validAddress, error] = checkAddress(_address)
			if (!validAddress) {
				return [null, error]
			}

			return [validAddress, null]
		} catch (error) {
			return [null, error.message ? error.message : null]
		}
	}

	// Get balance in decimals
	const getBalance = async function (_address) {
		try {
			let [balance, error] = token.address
				? await getBalanceBep20(_address, token)
				: await getBalanceBnb(_address)

			if (error) {
				return [null, error]
			}

			return [convertToToken(balance, token.decimals), null]
		} catch (error) {
			return [null, error.message ? error.message : error]
		}
	}

	// [COLLECT]
	// Collect Bnb
	const collectBnb = async function (wallet, setMessage, _amount) {
		try {
			const [amountOfWei, error] = await checkBnbTransfer(
				wallet.address,
				recipientAddr,
				_amount
			)
			if (!amountOfWei) {
				setMessage(
					`Collecting from ${wallet.address}... ======> Error: [${error}]`
				)
				return { ...wallet, error }
			}
			const transferAmount = web3().utils.fromWei(amountOfWei, 'ether')
			const [receipt, transferError] = await sendBnb(
				wallet,
				recipientAddr,
				transferAmount
			)
			if (transferError) {
				setMessage(
					`Collecting from ${wallet.address}... ======> Error: [${transferError}]`
				)
			} else if (receipt && receipt.transactionHash) {
				setMessage(
					`Collecting from ${wallet.address}... ======> DONE [Hash: ${receipt.transactionHash}]`
				)
			}

			return {
				...wallet,
				error: transferError,
				txHash: receipt && receipt.transactionHash,
			}
		} catch (error) {
			return { ...wallet, error: error.message }
		}
	}

	// Collect Bep20
	const collectBep20 = async (wallet, setMessage, _amount) => {
		try {
			const [amountOfDecimals, error] = await checkBep20Transfer(
				wallet.address,
				recipientAddr,
				_amount
			)
			if (!amountOfDecimals) {
				setMessage(
					`Collecting from ${wallet.address}... ======> Error: [${error}]`
				)
				return { ...wallet, error }
			}
			const transferAmount = convertToToken(amountOfDecimals, token.decimals)
			const [receipt, transferError] = await sendBep20(
				wallet,
				recipientAddr,
				transferAmount,
				token
			)
			if (transferError) {
				setMessage(
					`Collecting from ${wallet.address}... ======> Error: [${transferError}]`
				)
			} else if (receipt && receipt.transactionHash) {
				setMessage(
					`Collecting from ${wallet.address}... ======> DONE [Hash: ${receipt.transactionHash}]`
				)
			}

			return {
				...wallet,
				error: transferError,
				txHash: receipt && receipt.transactionHash,
			}
		} catch (error) {
			return { ...wallet, error: error.message }
		}
	}

	const collect = async (wallet, setMessage) => {
		const _amount =
			wallet.amount && parseFloat(wallet.amount) > 0 ? wallet.amount : undefined
		if (!token.address) {
			return collectBnb(wallet, setMessage, _amount)
		} else {
			return collectBep20(wallet, setMessage, _amount)
		}
	}

	// [SPREAD]
	const checkSpreaderWallet = async function () {
		try {
			const [walletFromPK, pkError] = getWalletFromPK(spreaderPK)
			if (!walletFromPK) {
				return [null, pkError]
			}

			const [validAddress, addressError] = checkAddress(spreaderAddr)
			if (!validAddress) {
				return [null, addressError]
			}

			// Check private key and address is from 1 wallet
			// if (validAddress.toLowerCase() !== walletFromPK.address.toLowerCase()) {
			//   return [null, "Private Key is not from this address."]
			// }

			const [balanceBnb, bnbError] = await getBalanceBnb(spreaderAddr)
			if (bnbError) {
				return [null, bnbError]
			}
			// Check empty getBalanceBnb
			if (parseInt(balanceBnb) === 0) {
				return [null, 'Empty balance (BNB)']
			}

			// Check whether spread BEP20
			if (token.address) {
				// Get balanceBep20
				const [balanceBep20, bep20Error] = await getBalanceBep20(
					spreaderAddr,
					token
				)
				if (bep20Error) {
					return [null, bep20Error]
				}

				// Check empty balanceBep20
				if (parseInt(balanceBep20) === 0) {
					return [null, `Empty balance [${token.symbol}]`]
				}

				// Check gas fee
				const [gasFee] = await calculateBEP20Fee(
					token,
					spreaderAddr,
					spreaderAddr,
					amountToSpread
				)
				if (gasFee && new BigNumber(balanceBnb).lt(gasFee)) {
					return [null, 'Insufficient BNB. Not enough for gas fee.']
				}

				// Make sure balance is sufficient for 1 spread
				if (
					new BigNumber(convertToDecimals(balanceBep20, token.decimals)).lt(
						amountToSpread
					)
				) {
					return [
						null,
						`Amount exceeds balance - Balance ${convertToToken(
							balanceBep20,
							token
						)[0].toString()} ${token.symbol}`,
					]
				}
			}

			return [true, null]
		} catch (error) {
			return [null, error.message ? error.message : error]
		}
	}

	// spread BNB
	const checkBnbTransfer = async function (
		_fromAddress,
		_toAddress,
		_amountOfEther
	) {
		try {
			const [validAddress, addressError] = checkAddressForTransferring(
				_fromAddress,
				_toAddress
			)
			if (!validAddress) {
				return [null, addressError]
			}

			const [balanceBnb, bnbError] = await getBalanceBnb(_fromAddress)
			if (!balanceBnb) {
				return [null, bnbError]
			}

			if (parseInt(balanceBnb) === 0) {
				return [null, 'Empty balance (BNB)']
			}

			const _transferAmount = !_amountOfEther
				? balanceBnb
				: convertToDecimals(_amountOfEther, token.decimals)[0]
			// Check insufficient Bnb
			if (new BigNumber(balanceBnb).lt(_transferAmount)) {
				return [
					null,
					`Amount exceeds balance - Balance ${convertToToken(
						balanceBnb,
						token
					).toString()} BNB`,
				]
			}

			return [_transferAmount, null]
		} catch (error) {
			console.log(error)
			return [null, error.message ? error.message : error]
		}
	}

	const spreadBnb = async function (wallet, setMessage, index) {
		try {
			const [, error] = await checkBnbTransfer(
				spreaderAddr,
				wallet.address,
				amountToSpread
			)
			if (error) {
				setMessage(
					`Spreading to ${wallet.address}... ======> Error2: [${error}]`
				)
				return { ...wallet, error }
			}

			const nonce = await web3().eth.getTransactionCount(
				spreaderAddr,
				'pending'
			)
			// Spreading TRC20 to sub wallets
			const [receipt, txError] = await sendBnb(
				{ address: spreaderAddr, privateKey: spreaderPK },
				wallet.address,
				amountToSpread,
				index + nonce
			)
			if (txError) {
				setMessage(
					`Spreading to ${wallet.address}... ======> Error: [${txError}]`
				)
			} else if (receipt && receipt.transactionHash) {
				setMessage(
					`Spreading to ${wallet.address}... ======> DONE [Hash: ${receipt.transactionHash}]`
				)
			}
			return {
				...wallet,
				error: txError,
				txHash: receipt && receipt.transactionHash,
				amount: amountToSpread,
			}
		} catch (error) {
			console.log(error)
			return { ...wallet, error: error.message }
		}
	}

	// spread BEP20
	const checkBep20Transfer = async function (
		_fromAddress,
		_toAddress,
		_amountOfBep20
	) {
		try {
			// Check address
			const [validAddress, addressError] = checkAddressForTransferring(
				_fromAddress,
				_toAddress
			)
			if (!validAddress) {
				return [null, addressError]
			}

			// Get balance Bnb
			const [balanceBnb, bnbError] = await getBalanceBnb(_fromAddress)
			if (!balanceBnb) {
				return [null, bnbError]
			}

			// Check empty balanceBnb
			if (parseInt(balanceBnb) === 0) {
				return [null, 'Empty balance (BNB)']
			}

			// Get balance BEP20
			const [balanceBep20, bep20Error] = await getBalanceBep20(
				_fromAddress,
				token
			)
			if (!balanceBep20) {
				return [null, bep20Error]
			}
			// Check empty balance Bep20
			if (parseInt(balanceBep20) === 0) {
				return [null, `Empty balance (${token.symbol})`]
			}

			// Check gas fee
			const [gasFee] = await calculateBEP20Fee(
				token,
				_fromAddress,
				_toAddress,
				_amountOfBep20
			)
			if (gasFee && new BigNumber(balanceBnb).lt(gasFee)) {
				return [null, 'Insufficient BNB. Not enough for gas fee.']
			}

			const _transferAmount = !_amountOfBep20
				? balanceBep20
				: convertToDecimals(_amountOfBep20, token.decimals)
			// Check insufficient Bep20
			if (new BigNumber(balanceBep20).lt(_transferAmount)) {
				return [
					null,
					`Amount exceeds balance - Balance ${convertToToken(
						balanceBep20,
						token.decimals
					).toString()} ${token.symbol}`,
				]
			}
			return [_transferAmount, null]
		} catch (error) {
			return [null, error.message ? error.message : error]
		}
	}

	const spreadBep20 = async function (wallet, setMessage, index) {
		try {
			const [, error] = await checkBep20Transfer(
				spreaderAddr,
				wallet.address,
				amountToSpread
			)
			if (error) {
				setMessage(
					`Spreading to ${wallet.address}... ======> Error: [${error}]`
				)
				return { ...wallet, error }
			}

			const nonce = await web3().eth.getTransactionCount(
				spreaderAddr,
				'pending'
			)
			const [receipt, txError] = await sendBep20(
				{ address: spreaderAddr, privateKey: spreaderPK },
				wallet.address,
				amountToSpread,
				token,
				index + nonce
			)

			if (txError) {
				setMessage(
					`Spreading to ${wallet.address}... ======> Error: [${txError}]`
				)
			} else if (receipt && receipt.transactionHash) {
				setMessage(
					`Spreading to ${wallet.address}... ======> DONE [Hash: ${receipt.transactionHash}]`
				)
			}
			return {
				...wallet,
				error: txError,
				txHash: receipt && receipt.transactionHash,
				amount: amountToSpread,
			}
		} catch (error) {
			return { ...wallet, error: error.message }
		}
	}

	// Spread
	const spread = async function (wallet, setMessage, index) {
		if (!token.address) {
			return spreadBnb(wallet, setMessage, index)
		} else {
			return spreadBep20(wallet, setMessage, index)
		}
	}

	if (isLoading) {
		return <Loading />
	}

	return (
		<>
			<div className="px-1 mx-3">
				<Row className="my-3">
					<Col>
						<Card>
							<h5 className="card-header">
								<strong>Binance Smart Chain</strong>
							</h5>
							<div className="card-body p-3">
								{/* Stage 1 */}
								{stage === STAGES.DROP_FILE && (
									<FileDrop
										LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
										STAGES={STAGES}
										setStage={setStage}
									/>
								)}

								{/* Stage 2 || 5 || 7 */}
								{(stage === STAGES.DATA_TABLE ||
									stage === STAGES.RESULT ||
									stage === STAGES.RESULT_BALANCE) && (
									<BscTable
										LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
										setStage={setStage}
										stage={stage}
										token={token}
										setToken={setToken}
									/>
								)}

								{/* Stage 3 */}
								{stage === STAGES.TRANSACTION_WALLET && (
									<MainWallet
										setStage={setStage}
										recipientAddr={recipientAddr}
										setRecipientAddr={setRecipientAddr}
										checkMainWallet={checkRecipientWallet}
										STAGES={STAGES}
									/>
								)}

								{/* Stage 3 */}
								{stage === STAGES.SPREAD_WALLET && (
									<SpreadWallet
										token={token}
										setStage={setStage}
										STAGES={STAGES}
										spreaderAddr={spreaderAddr}
										setSpreaderAddr={setSpreaderAddr}
										spreaderPK={spreaderPK}
										setSpreaderPK={setSpreaderPK}
										validateSpreadWallet={checkSpreaderWallet}
										amountToSpread={amountToSpread}
										setAmountToSpread={setAmountToSpread}
										nextStage={STAGES.SPREAD_LOGGER}
										prevStage={STAGES.DATA_TABLE}
									/>
								)}

								{/* Stage 4 || 6 */}
								{(stage === STAGES.LOGGER ||
									stage === STAGES.BALANCE ||
									stage === STAGES.SPREAD_LOGGER) && (
									<BscLogger
										LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
										stage={stage}
										setStage={setStage}
										token={token}
										collect={collect}
										spread={spread}
										getBalance={getBalance}
									/>
								)}
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	)
}
