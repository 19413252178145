import { Form, FormLabel, FormGroup, FormControl } from "react-bootstrap"
import { FaArrowLeft, FaWallet, FaKey, FaCoins } from "react-icons/fa"

import { useState, useEffect } from "react"
import { useGlobalContext } from "../../../context/global"

export default function Wallet({
  setStage,
  spreaderAddr,
  setSpreaderAddr,
  spreaderPK,
  setSpreaderPK,
  validateSpreadWallet,
  token,
  amountToSpread,
  setAmountToSpread,
  nextStage,
  prevStage,
}) {
  const { setIsLoading } = useGlobalContext()
  const [isChecking, setIsChecking] = useState(false)
  const [error, setError] = useState("")

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const handleSubmit = function (e) {
    e.preventDefault()
    setError('')
    setIsChecking(true)

    validateSpreadWallet()
      .then((result) => {
        const [validWallet, walletError] = result

        if (!validWallet) {
          setError(walletError)
        } else if (parseFloat(amountToSpread) === 0) {
          setError('Amount must be larger than 0.')
        }
        
        setIsChecking(false)
        if (validWallet) {
          setStage(nextStage)
        }
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <small>
        <button className="btn btn-secondary py-1 px-2" onClick={(e) => setStage(prevStage)}>
          <FaArrowLeft style={{ fontSize: "13px" }} />
        </button>
      </small>
      <Form onSubmit={handleSubmit} className="mt-4">
        {/* Address */}
        <FormGroup>
          <FormLabel>
            <FaWallet className="mr-2" />
            Địa chỉ ví tổng:
          </FormLabel>
          <FormControl
            type="text"
            required
            name="walletAddress"
            onChange={(e) => setSpreaderAddr(e.target.value.toString().trim())}
            value={spreaderAddr}
          />
        </FormGroup>
        {/* PK */}
        <FormGroup>
          <FormLabel>
            <FaKey className="mr-2" />
            PK ví tổng:
          </FormLabel>
          <FormControl
            type="text"
            required
            name="walletPK"
            onChange={(e) => setSpreaderPK(e.target.value.toString().trim())}
            value={spreaderPK}
          />
        </FormGroup>
        {/* Token */}
        {/* <Form.Group controlId="exampleForm.SelectCustom">
          <Form.Label><FaCoins className="mr-2"/>Token</Form.Label>
          {token ? (
          <Form.Control 
            type="text" value={token.symbol} disabled>
          </Form.Control>
          ) : (
            <Form.Control 
              type="text" value="TRX" disabled>
            </Form.Control>
          )}
        </Form.Group> */}

        <Form.Group controlId="exampleForm.SelectCustom">
          <Form.Label>
            <FaCoins className="mr-2" />
            Amount
          </Form.Label>
          <Form.Control
            type="number"
            value={amountToSpread}
            step="any"
            precision={5}
            onChange={(e) => setAmountToSpread(parseFloat(e.target.value))}
            min={0}
          ></Form.Control>
        </Form.Group>
        {/* Alert message */}
        <FormGroup>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </FormGroup>
        {/* Loader */}
        {isChecking && (
          <div className="text-center my-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        )}

        <FormGroup>
          <FormControl
            className="btn btn-primary"
            type="submit"
            value="Send"
            disabled={isChecking ? "disabled" : ""}
          />
        </FormGroup>
      </Form>
    </>
  )
}
