import { Link } from "react-router-dom"
import { useEffect } from 'react'
import {useGlobalContext} from '../../context/global'

import NavLink from './NavLink'


export default function Nav() {
  const {ROUTES, currentPath, setCurrentPath} = useGlobalContext()

  useEffect(() => {
    
    
  }, [])

  const switchRoute = function (e) {
    const targetRoute = e.target.id 

    switch(targetRoute) {
      case 'eth': {
        setCurrentPath('eth')
        break;
      }
      case 'erc20': {
        setCurrentPath('erc20')
        break;
      }
      case 'trx': {
        setCurrentPath('trx')
        break;
      }
      case 'trc20': {
        setCurrentPath('trc20')
        break;
      }
      case 'balance': {
        setCurrentPath('balance')
        break;
      }
      case 'spread': {
        setCurrentPath('spread')
        break;
      }
      case 'bsc': {
        setCurrentPath('bsc')
        break;
      }
      default: {
        setCurrentPath('')
      }
    }
  }
  
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <Link 
            id="home-route"
            className="navbar-brand" 
            onClick={switchRoute}
            to="/">
            Pi Tech
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              {ROUTES.map((route, index) => (
                <NavLink 
                  key={index}
                  active={currentPath === route}
                  route={route}
                  path={`/${route}`} 
                  switchRoute={switchRoute} />
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
