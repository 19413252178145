import {Link} from 'react-router-dom'
import {NavDropdown, Nav} from 'react-bootstrap'

export default function NavLink({route, active, path, switchRoute}) {
  return (
    <>
    <li className="nav-item">
    {route === 'wallets' ? (
    <NavDropdown title="WALLETS" id="wallets" 
      className={`nav-link text-uppercase ${active ? "active" : ""}`}>
      <NavDropdown.Item href="/wallets/ethereum">Ethereum</NavDropdown.Item>
      <NavDropdown.Item href="/wallets/tron">Tron</NavDropdown.Item>
    </NavDropdown>
    ) : (
      <Nav.Link>
        <Link
          id={route}
          className={`nav-link text-uppercase ${active ? "active" : ""}`}
          to={path}
          onClick={switchRoute}
        >
          {route}
        </Link>
        </Nav.Link>
      )}
      </li>
    </>
  )
}
