import { useContext, createContext } from "react"
import Web3 from "web3"
import { WEB3_MAINNET_RPC, WEB3_ROPSTEN_RPC } from "../constant/ethereum"
import BigNumber from 'bignumber.js'
BigNumber.config({ EXPONENTIAL_AT: 30 })
const EthereumContext = createContext()
const DIFF_RATIO = 0.2 // 
const pollingTimeout = 180

export const EthereumProvider = function ({ children }) {
  const web3 = new Web3(WEB3_MAINNET_RPC)
  // const web3 = new Web3(WEB3_ROPSTEN_RPC)
  web3.eth.transactionPollingTimeout = pollingTimeout

  // Get balanceETH in wei
  const getBalanceETH = async function (address) {
    try {
      address = address.toString().trim()
      return { balanceETH: await web3.eth.getBalance(address) }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Get decimals of token trc20
  const getDecimals = async function (ERC20_TOKEN) {
    try {
      const contractInstance = new web3.eth.Contract(ERC20_TOKEN.ABI, ERC20_TOKEN.address)
      const decimals = 10 ** (await contractInstance.methods.decimals().call())

      return { decimals }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Get balanceERC in decimals
  const getBalanceERC20 = async function (fromAddress, ERC20_TOKEN) {
    try {
      fromAddress = fromAddress.toString().trim()
      const contractInstance = new web3.eth.Contract(ERC20_TOKEN.ABI, ERC20_TOKEN.address)
      const {decimals, error} = await getDecimals(ERC20_TOKEN)
      if (error) {
        return {error}
      }
      let balanceERC20 = await contractInstance.methods.balanceOf(fromAddress).call()
      balanceERC20 = balanceERC20.toString()

      return {balanceERC20, decimals}
    } catch (error) {
      return { error: error.message }
    }
  }

  // Calculate gas fee
  const calculateGasFeeETH = async function (toAddress, transferData = '0x') {
    try {
      toAddress = toAddress.toString().trim()
      let estimateGas = await web3.eth.estimateGas({to: toAddress, data: transferData})
      let gasPrice = await web3.eth.getGasPrice()
      gasPrice = Math.ceil(parseInt(gasPrice) + (parseInt(gasPrice) * 0.1))
      let gasFee = Number.parseInt(gasPrice) * estimateGas
      gasFee = gasFee + parseInt(gasFee * DIFF_RATIO) 
      
      return {gasFee, estimateGas, gasPrice}
    } catch (error) {
      return {error: error.message}
    }
  }

  // Check amount, balance, transferValue
  const checkTransferETH = async function(toAddress, balanceETH, amountETH) {
    try {
      toAddress = toAddress.toString().trim()
      // Get gas fee
      const {gasFee, error} = await calculateGasFeeETH(toAddress)
      if(error) {
        return {error}
      }

      // Check 0 balance
      if (parseInt(balanceETH) === 0) {
        return {error: `Insufficient balance (ETH) - ${web3.utils.fromWei(balanceETH.toString(), 'ether')}`}
      }

      // Check amount > balance
      if (parseInt(amountETH) > parseInt(balanceETH)) {
        return {error: `Amount to transfer exceeds balance (ETH) - Bal: ${web3.utils.fromWei(balanceETH.toString(), 'ether')}`}
      }

      // Check 0 ETH (amount)
      if (parseInt(amountETH) === 0) {
        return {error: `Can not transfer with 0 amount (ETH) - ${web3.utils.fromWei(balanceETH.toString(), 'ether')}`}
      }

      // Reject if (gas >= amount || gas <= (amount*2))
      if (gasFee >= parseInt(amountETH)) {
        return {error: `[Gas fee higher than amount! ${web3.utils.fromWei(gasFee.toString(), 'ether')}]`}
      }

      const transferValue = parseInt(amountETH) - Number.parseInt(gasFee)
      // Check balance < transferValue
      if (transferValue > balanceETH) {
        return {error: `[Transfer value exceeds balance (ETH) - ${web3.utils.fromWei(transferValue.toString(), 'ether')}]`}
      }

      // Check transfer value < gas Fee
      if (transferValue <= gasFee)  {
        return {error: `[Transfer value is less than gas fee after the transaction] - ${web3.utils.fromWei(transferValue.toString(), 'ether')}`}
      }

      return {isValid: true}
    } catch (error) {
      return {error}
    }
  }

  const sendETH = async function (from, toAddress, amountETH) {
    try {
      // Trim data
      from.address = from.address.toString().trim()
      from.privateKey = from.privateKey.toString().trim()
      toAddress = toAddress.toString().trim()
      
      // Calculate gas
      let {gasFee, error} = await calculateGasFeeETH(toAddress)
      if (error) {
        return {error: `[${error}]`}
      }

      // Reject if (gas >= amount)
      if (gasFee > parseInt(amountETH) ) {
        return {error: `[Gas fee too high! ${web3.utils.fromWei(gasFee.toString(), 'ether')}]`}
      }

      // Calculate transaction value
      const transactionValue = Number.parseInt(amountETH) - Number.parseInt(gasFee)
      // Create transaction 
      const transactionObject = {
        from: from.address,
        // gasPrice: web3.utils.toHex(parseInt(gasPrice.toString())),
        gas: "21000", // Gas limit
        to: toAddress,
        value: web3.utils.toHex(new BigNumber(transactionValue.toString())), // in wei
        data: "0x"
      }

      // Sign transaction
      const signedTransaction = await web3.eth.accounts.signTransaction(transactionObject, from.privateKey)

      // Send signed transaction
      const receipt = await web3.eth.sendSignedTransaction(signedTransaction.rawTransaction)

      return {txHash: receipt.transactionHash}
    } catch (error) {
      return {error: error.message}
    }
  }

  // Check address (from, to)
  const checkAddresses = async function (fromAddress, toAddress) {
    try {
      // Trim data
      fromAddress = fromAddress.toString().trim()
      toAddress = toAddress.toString().trim()

      // Check address of fromWallet
      const isValidSender = web3.utils.isAddress(fromAddress)
      if (!isValidSender) {
        return {error: `Invalid address [${fromAddress}]`}
      }
      
      // Check toAddress
      const isValidRecipient = web3.utils.isAddress(toAddress)
      if (!isValidRecipient) {
        return {error: `Invalid address [${toAddress}]`}
      }
      
      // Same address
      if (fromAddress === toAddress) {
        return {error: `Same address [${fromAddress}]`}
      }

      return {isValid: true}
    } catch (error) {
      return {error}
    }
  }

  // Convert from decimals to token
  const convertToToken = function (amountToken, decimals) {
    let convertAmount = new BigNumber(amountToken.toString())
    convertAmount = convertAmount.dividedBy(parseInt(decimals))
    return { convertAmount }
  }

  // Convert from decimals to token
  const convertToDecimals = function (amountToken, decimals) {
    let convertAmount = new BigNumber(amountToken.toString())
    // BigNumber.config({ DECIMAL_PLACES: decimals.toString().length })
    convertAmount = convertAmount.multipliedBy(parseInt(decimals))
    return { convertAmount }
  }

  // Validation for transferring ERC20
  const checkTransferERC20 = async function (wallet, toAddress, amountERC20, ERC20_TOKEN) {
    try {
      // Trim data
      toAddress = toAddress.toString().trim()
      wallet.address = wallet.address.toString().trim()
      wallet.privateKey = wallet.privateKey.toString().trim()

      const contractInstance = new web3.eth.Contract(ERC20_TOKEN.ABI, ERC20_TOKEN.address)
      // Get balanceETH
      const { balanceETH, error: balanceETHErr } = await getBalanceETH(wallet.address)
      if (balanceETHErr) {
        return { ...wallet, error: balanceETHErr }
      }

      // Check empty balanceETH
      if (parseInt(balanceETH) === 0) {
        return {...wallet, error: `Empty balance (ETH)`, balanceETH: 0}
      }
      
      // Get balanceERC20
      const { balanceERC20, error: getBLError, decimals } = await getBalanceERC20(
        wallet.address,
        ERC20_TOKEN
      )
      if (getBLError) {
        return { ...wallet, error: getBLError }
      }

      // Check empty balanceERC20
      if (parseInt(balanceERC20) === 0) {
        return {...wallet, error: `Empty balance - [${ERC20_TOKEN.symbol}]`}
      }

      // Check balanceERC20 is sufficient for amountERC20 (in decimals)
      if (parseInt(balanceERC20) < parseInt(amountERC20)) {
        return {...wallet, error: `Amount exceeds balance (${ERC20_TOKEN.symbol}) - Balance: ${convertToToken(parseInt(balanceERC20), decimals).convertAmount}`}
      }

      // Calculate gas 
      const { gasFee } = await calTransferERC20Gas(wallet.address, toAddress, amountERC20, contractInstance)
      if (parseInt(balanceETH) < parseInt(gasFee)) {
        return {
          ...wallet,
          error: `Not enough gas`,
          gasFee
        }
      }

      return {isValid: true, gasFee}
    } catch (error) {
      return {error}
    }
  }

  // Calculate Contract gas
  const calTransferERC20Gas = async function (fromAddress, toAddress, amount, contractInstance){
    try {
      // Trim data
      toAddress = toAddress.toString().trim()
      fromAddress = fromAddress.toString().trim()

      const estimateGas = await contractInstance.methods.transfer(toAddress, amount).estimateGas({from: fromAddress})
      let gasPrice = await web3.eth.getGasPrice()
      let gasFee = Number.parseInt(gasPrice) * estimateGas
      gasFee = gasFee + parseInt(gasFee * DIFF_RATIO)

      return {estimateGas, gasFee}
    } catch (error) {
      return {error}
    }
  }

  // Send ERC20
  const sendERC20 = async function (from, toAddress, amountERC20, ERC20_TOKEN) {
    try {
      // Trim data
      toAddress = toAddress.toString().trim()
      from.address = from.address.toString().trim()
      from.privateKey = from.privateKey.toString().trim()

      const contractInstance = new web3.eth.Contract(ERC20_TOKEN.ABI, ERC20_TOKEN.address)
      // Convert amountERC20
      amountERC20 = new BigNumber(amountERC20.toString())
      // Calculate transfer data
      const transferData = await contractInstance.methods.transfer(toAddress, amountERC20.toString()).encodeABI()
      
      // Calculate gas
      const {estimateGas} = await calTransferERC20Gas(from.address, toAddress, amountERC20.toString(), contractInstance)
    
      // Create transaction 
      const transactionObject = {
        from: from.address,
        gas: web3.utils.toHex(estimateGas.toString()), // Gas limit
        to: ERC20_TOKEN.address,
        value: "0", // in wei
        data: web3.utils.toHex(transferData)
      }

      // Sign transaction
      const signedTransaction = await web3.eth.accounts.signTransaction(transactionObject, from.privateKey)

      // Send signed transaction
      const receipt = await web3.eth.sendSignedTransaction(signedTransaction.rawTransaction)
      
      return {txHash: receipt.transactionHash}
    } catch (error) {
      return {error: error.message}
    }
  }

  // Check amountETH vs balanceETH before provide
  const checkProvideETH = async function(from, toAddress, amountETH) {
    try {
      // Get gas fee
      const {gasFee, error: gasFeeErr} = await calculateGasFeeETH(toAddress)
      if (gasFeeErr) {
        return {error: gasFeeErr}
      }

      // Get balanceETH
      const {balanceETH, error: getBalErr} = await getBalanceETH(from.address)
      if (getBalErr) {
        return {error: getBalErr}
      }

      // Check empty balanceETH
      if (!balanceETH) {
        return {error: `Empty balance (ETH)`}
      }

      // Calculate transferAmount
      const transferAmount = parseInt(amountETH) + parseInt(gasFee)

      // Check insufficient transferAmount
      if (transferAmount > parseInt(balanceETH)) {
        return  {error: `Insufficient balance (ETH) - Provide: ${web3.utils.fromWei(gasFee.toString())}`}
      }

      return {isValid: true}
    } catch (error) {
      return {error: error.message}
    }
  }

  // Spread ETH 
  const spreadETH = async function (from, toAddress, amountETH, index) {
    try {
      // Get gas fee
      const {gasFee, error: gasFeeErr, estimateGas, gasPrice} = await calculateGasFeeETH(toAddress)
      if (gasFeeErr) {
        return {error: gasFeeErr}
      }

      // get balanceETH (must)
      const {balanceETH, error: balErr} = await getBalanceETH(from.address)
      if (balErr) {
        return {error: balErr}
      }

      // Calculate transferAmount
      const transferAmount = parseInt(amountETH) + parseInt(gasFee)
      // Check balance is sufficient
      if(balanceETH < transferAmount) {
        return {error: `Insufficient balance - Required: ${parseInt(transferAmount)}`}
      }
      // Get nonce
      let nonce = await web3.eth.getTransactionCount(from.address, "pending")
      // Create transaction 
      const transactionObject = {
        nonce: web3.utils.toHex(nonce + index),
        from: from.address,
        gasPrice: web3.utils.toHex(parseInt(gasPrice.toString()) * 2),
        gas: web3.utils.toHex(estimateGas.toString()), // Gas limit
        to: toAddress,
        value: web3.utils.toHex(amountETH.toString()), // in wei
        // data: "0x"
      }
      
      // Sign transaction
      const signedTransaction = await web3.eth.accounts.signTransaction(transactionObject, from.privateKey)

      // Send signed transaction
      const receipt = await web3.eth.sendSignedTransaction(signedTransaction.rawTransaction)
      
      return {txHash: receipt.transactionHash}
    } catch (error) {
      if (error.message === `Transaction was not mined within ${pollingTimeout} seconds, please make sure your transaction was properly sent. Be aware that it might still be mined!`) {
        return { error: `Polling timeout: ${pollingTimeout / 60}m` }
      }
      return { error: error.message }
    }
  }

  // Spread ERC20
  const spreadERC20 = async function (from, toAddress, amountERC20, ERC20_TOKEN, index) {
    try {
      // Trim data
      toAddress = toAddress.toString().trim()
      from.address = from.address.toString().trim()
      from.privateKey = from.privateKey.toString().trim()

      const contractInstance = new web3.eth.Contract(ERC20_TOKEN.ABI, ERC20_TOKEN.address)
      // Convert amountERC20
      amountERC20 = new BigNumber(amountERC20.toString())
      // Calculate transfer data
      const transferData = await contractInstance.methods.transfer(toAddress, amountERC20.toString()).encodeABI()
      
      // Calculate gas
      const {estimateGas} = await calTransferERC20Gas(from.address, toAddress, amountERC20.toString(), contractInstance)
      
      // Get nonce
      let nonce = await web3.eth.getTransactionCount(from.address, "pending")
      // Create transaction 
      const transactionObject = {
        nonce: web3.utils.toHex(nonce + index),
        from: from.address,
        gas: web3.utils.toHex(estimateGas.toString()), // Gas limit
        to: ERC20_TOKEN.address,
        value: "0", // in wei
        data: web3.utils.toHex(transferData)
      }

      // Sign transaction
      const signedTransaction = await web3.eth.accounts.signTransaction(transactionObject, from.privateKey)

      // Send signed transaction
      const receipt = await web3.eth.sendSignedTransaction(signedTransaction.rawTransaction)
      
      return {txHash: receipt.transactionHash}
    } catch (error) {
      return {error: error.message}
    }
  }

 // Convert result array for downloader
  const parseEtherResultDownload = (jsonData) => {
    const downloadData = jsonData.map((item) => {
      return {
        address: item.address,
        gas: item.gasFee,
        balanceETH: item.balanceETH,
        amount: item.amount,
        error: item.error,
        hash: item.txHash,
      }
    })

    return downloadData
  }

  // Create account by quantity
  const createAccounts = (quantity) => {
    if (quantity <= 0) {
      throw new Error("Can not generate wallet with the quantity of 0")
    }

    const generatedWallets = []

    for (let i = 0; i < quantity; i++) {
      const newAccount = web3.eth.accounts.create(Crypto.randomBytes(32).toString('hex'))
      generatedWallets.push({
        index: i + 1,
        address: newAccount.address,
        privateKey: newAccount.privateKey,
      })
    }

    return {wallets: generatedWallets}
  }

  return (
    <EthereumContext.Provider
      value={{
        web3,
        getBalanceETH,
        getBalanceERC20,
        sendETH,
        sendERC20,
        checkAddresses,
        checkTransferETH,
        checkTransferERC20,
        checkProvideETH,
        convertToToken,
        convertToDecimals,
        calculateGasFeeETH,
        spreadETH,
        spreadERC20,
        parseEtherResultDownload,
        createAccounts
      }}
    >
      {children}
    </EthereumContext.Provider>
  )
}

export const useEthereumContext = function () {
  return useContext(EthereumContext)
}
