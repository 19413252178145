import Nav from "../components/Nav/Nav"
import Home from "./Home"
import ETH from "./ETH"
import ERC20 from "./ERC20"
import TRX from "./TRX"
import TRC20 from "./TRC20"
import Balance from "./Balance"
import BSC from "./BSC"
import { Route } from "react-router-dom"
import { EthereumProvider } from "../context/ethereum"
import { TronProvider } from "../context/tron"
import TronWallets from "./TronWallets"
import EtherWallets from "./EtherWallets"

export default function Layout() {
  return (
    <>
      <Nav />

      <Route exact path="/">
        <Home />
      </Route>

      <Route exact path="/bsc">
        <BSC />
      </Route>
      <EthereumProvider>
        <Route exact path="/eth">
          <ETH />
        </Route>

        <Route exact path="/erc20">
          <ERC20 />
        </Route>

        <Route exact path="/wallets/ethereum">
          <EtherWallets />
        </Route>
      </EthereumProvider>

      <TronProvider>
        <Route exact path="/trx">
          <TRX />
        </Route>

        <Route exact path="/trc20">
          <TRC20 />
        </Route>

        <Route exact path="/wallets/tron">
          <TronWallets />
        </Route>
      </TronProvider>
      
      <EthereumProvider>
        <TronProvider>
          <Route exact path="/balance">
            <Balance />
          </Route>
        </TronProvider>
      </EthereumProvider>
    </>
  )
}
