// Binance Smart Chain
// TOKEN BEP20
export const BSC_TOKENS = [
  {
    symbol: "BNB",
    address: undefined,
    decimals: 18
  },
  {
    symbol: "EBP",
    address: "0x2570f960e1f2992721ee04d090c1cc2d92293e12",
    decimals: 6
  },
  {
    symbol: "BUSD",
    address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    decimals: 18
  },
  {
    symbol: "HBG",
    address: "0x8c2Da84EA88151109478846cc7c6c06C481dbe97",
    decimals: 18
  },
  {
    symbol: "USDT",
    address: "0x55d398326f99059ff775485246999027b3197955",
    decimals: 18
  },
  {
    symbol: "DP-NFT",
    address: "0xc31c29D89e1c351D0A41b938dC8AA0b9F07B4a29",
    decimals: 9
  },
  {
    symbol: "DPC",
    address: "0xbc9695302321594e7daedc5a974f4562f71c588c",
    decimals: 18
  },
]
