import { Form, FormLabel, FormGroup, FormControl } from "react-bootstrap"
import { FaArrowLeft, FaWallet, FaKey } from "react-icons/fa"

import { useState, useEffect } from "react"
import { useGlobalContext } from "../../../context/global"
import { useTronContext } from "../../../context/tron"
import {TOKENS as TRON_TOKENS} from '../../../constant/tron'

export default function Wallet({
  setStage,
  activateWalletAddr,
  setActivateWalletAddr,
  activateWalletPK,
  setActivateWalletPK,
  validateActivationWallet
}) {
  const { setIsLoading, WALLETS_STAGES } = useGlobalContext()
  const [isChecking, setIsChecking] = useState(false)
  const [error, setError] = useState("")
  const {setMainWallet} = useTronContext()

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const handleSubmit = function (e) {
    e.preventDefault()
    setIsChecking(true)
    const { walletAddress, walletPK } = e.target.elements
    // Set Spread wallet
    // setMainWallet(walletAddress.value, walletPK.value)
    // setSpreadWalletAddr(walletAddress.value)
    // setSpreadWalletPK(walletPK.value)

    validateActivationWallet()
      .then((result) => {
        const { error } = result
        if (error) {
          setError(error)
          setIsChecking(false)
          return
        }

        setIsChecking(false)
        setStage(WALLETS_STAGES.ACTIVATE)
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <small>
        <button className="btn btn-secondary py-1 px-2" onClick={(e) => setStage(WALLETS_STAGES.DATA_TABLE)}>
          <FaArrowLeft style={{ fontSize: "13px" }} />
        </button>
      </small>
      <Form onSubmit={handleSubmit} className="mt-4">
        {/* Address */}
        <FormGroup>
          <FormLabel>
            <FaWallet className="mr-2" />
            Địa chỉ ví tổng:
          </FormLabel>
          <FormControl
            type="text"
            required
            name="walletAddress"
            onChange={(e) => setActivateWalletAddr(e.target.value.toString().trim())}
            value={activateWalletAddr}
          />
        </FormGroup>
        {/* PK */}
        <FormGroup>
          <FormLabel>
            <FaKey className="mr-2" />
            PK ví tổng:
          </FormLabel>
          <FormControl
            type="text"
            required
            name="walletPK"
            onChange={(e) => setActivateWalletPK(e.target.value.toString().trim())}
            value={activateWalletPK}
          />
        </FormGroup>
        {/* Token */}

        {/* Alert message */}
        <FormGroup>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </FormGroup>
        {/* Loader */}
        {isChecking && (
          <div className="text-center my-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        )}

        <FormGroup>
          <FormControl
            className="btn btn-primary"
            type="submit"
            value="Send"
            disabled={isChecking ? "disabled" : ""}
          />
        </FormGroup>
      </Form>
    </>
  )
}
