import React from "react"
import LoggingMessage from "./LoggingMessage"

export default function Logger({ messages }) {
  return (
    <ol>
      {messages && messages.map((msg, index) => (
        <LoggingMessage key={index} message={msg} />
      ))}
    </ol>
  )
}
