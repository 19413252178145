import { Row, Col, Card } from "react-bootstrap"
import { useState, useEffect } from "react"
import { useGlobalContext } from "../context/global"
import { useTronContext } from "../context/tron"
import Loading from "../components/Loading"
import FileDrop from "../components/Stage/FileDrop/FileDrop"
import MainWallet from "../components/Stage/Wallet/MainWallet"
import SpreadWallet from "../components/Stage/Wallet/SpreadWallet"
import TRXTable from "../components/Stage/DataTable/TRXTable"
import TRXLogger from "../components/Stage/Logger/TRXLogger"

const LOCAL_STORAGE_NAME = "TRX-Wallets"

export default function TRX() {
  const { isLoading, setIsLoading, currentPath, TRX_STAGES } = useGlobalContext()
  const { getBalanceTRX, checkAddress, checkTransferTRX, sendTRX, tronWeb, isActive } =
    useTronContext()
  const [recipientAddr, setRecipientAddr] = useState("")
  const [spreaderAddr, setSpreaderAddr] = useState("")
  const [spreaderPK, setSpreaderPK] = useState("")
  const [amountToSpread, setAmountToSpread] = useState(0)
  const [stage, setStage] = useState(TRX_STAGES.DROP_FILE)

  useEffect(() => {
    setIsLoading(false)
    const walletList = localStorage.getItem(LOCAL_STORAGE_NAME)
    if (walletList) {
      setStage(TRX_STAGES.DATA_TABLE)
    } else {
      setStage(TRX_STAGES.DROP_FILE)
    }
  }, [])

  // Check main wallet
  const checkMainWallet = function (address) {
    address = address.toString().trim()
    if (tronWeb.isAddress(address)) {
      return [true, null]
    }

    return [null, false]
  }

  // Check spreader wallet
  const checkSpreaderWallet = async function () {
    try {
      // Check address format
      const isValidAddress = tronWeb.isAddress(spreaderAddr)
      if (!isValidAddress) {
        return [null, `Invalid format [${spreaderAddr}]`]
      }

      // Get balance TRX
      const { balanceTRX, error: balTRX_Err } = await getBalanceTRX(spreaderAddr)
      if (balTRX_Err) {
        return [null, balTRX_Err]
      }

      // Check balance TRX !== 0
      if (parseInt(balanceTRX) === 0) {
        return [null, `Empty balance (TRX)`]
      }

      // Check amountToSpread !== 0
      if (parseInt(amountToSpread) === 0) {
        return [null, `Can not spread with 0 amount.`]
      }

      // Check amountToSpread > balance TRX
      // Make sure at least spreader can spread 1 wallet
      if (parseInt(amountToSpread) > parseInt(balanceTRX)) {
        return [
          null,
          `Insufficient balance TRX - Balance: ${tronWeb.fromWei(balanceTRX.toString())}`,
        ]
      }

      return [true, null]
    } catch (error) {
      return [null, error.message ? error.message : error]
    }
  }

  // Validation clone wallets from file
  const validation = async function (wallet) {
    try {
      // Trim data
      wallet.address = wallet.address.toString().trim()
      wallet.privateKey = wallet.privateKey.toString().trim()

      // Check address format
      const { error: addressError } = await checkAddress(wallet.address, recipientAddr)
      if (addressError) {
        return { ...wallet, error: addressError }
      }

      // Check same address
      if (wallet.address === recipientAddr) {
        return { ...wallet, error: `Same address [${recipientAddr}]` }
      }

      // Get balanceTRX
      const { balanceTRX, error: getBLError } = await getBalanceTRX(wallet.address)
      if (getBLError) {
        return { ...wallet, error: getBLError }
      }
      wallet.balanceTRX = balanceTRX

      // Convert amount
      if (!wallet.amount) {
        wallet.amountTRX = balanceTRX // in sun
        wallet.amount = tronWeb.fromSun(balanceTRX.toString())
      } else {
        wallet.amountTRX = tronWeb.toSun(wallet.amount.toString())
      }

      // Check transfer TRX (!= 0 && amount < balance)
      const { error: transferTRXError } = checkTransferTRX(balanceTRX, wallet.amountTRX)
      if (transferTRXError) {
        return { ...wallet, error: transferTRXError }
      }

      return {
        validWallet: {
          ...wallet,
          // amount: amountTRX // converted to sun
        },
      }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Get balance TRX
  const getBalance = async function (address) {
    try {
      address = address.toString().trim()
      const { balanceTRX } = await getBalanceTRX(address)
      return { balanceTRX: tronWeb.fromSun(balanceTRX) }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Collect TRX
  const collect = async function (wallet, setMessage) {
    try {
      const { validWallet, error } = await validation(wallet)
      if (error) {
        setMessage(`Collecting from ${wallet.address}... ======>  ${error}  `)
        return { wallet, error }
      }

      // Collect TRX from sub wallets
      const { txHash, error: txHashError } = await sendTRX(
        { address: validWallet.address, privateKey: validWallet.privateKey },
        recipientAddr,
        validWallet.amountTRX
      )
      if (txHashError || !txHash) {
        setMessage(`Collecting from ${wallet.address}... ======>  ${txHashError}  `)
        return { wallet, error: txHashError }
      }
      if (txHash) {
        setMessage(`Collecting from ${validWallet.address}... ======> DONE [${txHash}]`)
      }

      return {
        wallet: {
          ...validWallet,
          txHash,
          balanceTRX: tronWeb.fromSun(validWallet.balanceTRX),
          error: txHashError,
        },
      }
    } catch (error) {
      return { wallet, error: error.message }
    }
  }

  // Validation for Spreader each sending TRX
  const validationSpreader = async function (toAddress) {
    try {
      // Trim data
      toAddress = toAddress.toString().trim()

      // Check format address
      if (!tronWeb.isAddress(toAddress)) {
        return { error: `Invalid format [${toAddress}]` }
      }

      // Check same address
      if (toAddress === spreaderAddr) {
        return { error: `Same address [${toAddress}]` }
      }

      // Check toWallet active
      // const active = await isActive(toAddress)
      // if (!active) {
      //   return {error: `Inactive account`}
      // }

      // Get balance
      const { balanceTRX, error: balTRX_Err } = await getBalanceTRX(spreaderAddr)
      if (balTRX_Err) {
        return { error: balTRX_Err }
      }

      // Check balance !== 0
      if (parseInt(balanceTRX) === 0) {
        return { error: `Empty balance (TRX)` }
      }

      // Check balance TRX >= amountToSpread
      if (parseInt(balanceTRX) < parseInt(tronWeb.toSun(parseFloat(amountToSpread).toString()))) {
        return { error: `Insufficient balance (TRX)` }
      }

      return { isValid: true }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Spread TRX
  const spread = async function (wallet, setMessage) {
    try {
      const { error } = await validationSpreader(wallet.address)
      if (error) {
        setMessage(`Spreading [${amountToSpread} TRX] to ${wallet.address}... ======>  ${error}  `)
        return { wallet, error }
      }

      const spreadAmountTRX = tronWeb.toSun(parseInt(amountToSpread))

      // Collect TRX from sub wallets
      const { txHash, error: txHashError } = await sendTRX(
        { address: spreaderAddr, privateKey: spreaderPK },
        wallet.address,
        parseFloat(spreadAmountTRX)
      )
      if (txHash) {
        setMessage(
          `Spreading [${amountToSpread} TRX] to ${wallet.address}... ======> DONE [${txHash}]`
        )

        return {
          wallet: {
            ...wallet,
            txHash,
            amount: parseInt(amountToSpread), // Convert to SUN for rendering
            error: txHashError,
          },
        }
      }

      setMessage(
        `Spreading [${amountToSpread} TRX] to ${wallet.address}... ======> Error: [${txHashError}]`
      )
      return { wallet, error }
    } catch (error) {
      return { wallet, error: error.message }
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className="px-1 mx-3">
        <Row className="my-3">
          <Col>
            <Card>
              <h5 className="card-header">
                Đồng <strong>{currentPath.toUpperCase()}</strong>
              </h5>
              <div className="card-body p-3">
                {/* Stage 1 */}
                {stage === TRX_STAGES.DROP_FILE && (
                  <FileDrop
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    STAGES={TRX_STAGES}
                    setStage={setStage}
                  />
                )}

                {/* Stage 2 || 5 || 7 */}
                {(stage === TRX_STAGES.DATA_TABLE ||
                  stage === TRX_STAGES.RESULT ||
                  stage === TRX_STAGES.RESULT_BALANCE) && (
                  <TRXTable
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    setStage={setStage}
                    stage={stage}
                  />
                )}

                {/* Stage 3  */}
                {stage === TRX_STAGES.TRANSACTION_WALLET && (
                  <MainWallet
                    setStage={setStage}
                    recipientAddr={recipientAddr}
                    setRecipientAddr={setRecipientAddr}
                    STAGES={TRX_STAGES}
                    checkMainWallet={checkMainWallet}
                  />
                )}

                {/* Stage 4  */}
                {stage === TRX_STAGES.SPREAD_WALLET && (
                  <SpreadWallet
                    setStage={setStage}
                    STAGES={TRX_STAGES}
                    spreaderAddr={spreaderAddr}
                    setSpreaderAddr={setSpreaderAddr}
                    spreaderPK={spreaderPK}
                    setSpreaderPK={setSpreaderPK}
                    validateSpreadWallet={checkSpreaderWallet}
                    amountToSpread={amountToSpread}
                    setAmountToSpread={setAmountToSpread}
                    nextStage={TRX_STAGES.SPREAD_LOGGER}
                    prevStage={TRX_STAGES.DATA_TABLE}
                  />
                )}

                {/* Stage 5 */}
                {(stage === TRX_STAGES.LOGGER ||
                  stage === TRX_STAGES.BALANCE ||
                  stage === TRX_STAGES.SPREAD_LOGGER) && (
                  <TRXLogger
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    setStage={setStage}
                    stage={stage}
                    collect={collect}
                    getBalance={getBalance}
                    spread={spread}
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
