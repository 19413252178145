import BigNumber from "bignumber.js"
import { useContext, createContext } from "react"
import TronWeb from "tronweb"
import {
  FULLHOST_URL,
  TRONGRID_API_KEY,
  balanceTRX_limit,
  max_feeLimit,
  FULLHOST_TESTNET_URL,
} from "../constant/tron"

const TronContext = createContext()
BigNumber.config({ EXPONENTIAL_AT: 40 })
// BALANCE =====>  parse to [SUN]
// AMOUNT =====>  parse to [SUN]
export const TronProvider = function ({ children }) {
  const unit = "TRX"
  const tronWeb = new TronWeb({
    fullHost: FULLHOST_URL, 
    // fullHost: FULLHOST_TESTNET_URL,
    headers: {
      "TRON-PRO-API-KEY": TRONGRID_API_KEY,
      "Content-Type": "application/json",
    },
    // privateKey: process.env.SENDER_PK
  })

  // Set main wallet
  const setMainWallet = (address, privateKey) => {
    address = address.toString().trim()
    privateKey = privateKey.toString().trim()

    if (tronWeb.isAddress(address)) {
      tronWeb.setAddress(address)
      tronWeb.setPrivateKey(privateKey)
    }
  }

  // Get balance of TRX by [TRX]
  const getBalanceTRX = async (address) => {
    try {
      address = address.toString().trim()
      return { balanceTRX: await tronWeb.trx.getBalance(address) }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Get decimals of token trc20
  const getDecimals = async function (fromAddress, TOKEN_ADDRESS) {
    try {
      fromAddress = fromAddress.toString().trim()
      tronWeb.setAddress(fromAddress)

      const contractInstance = await tronWeb.contract().at(TOKEN_ADDRESS)
      const decimals = 10 ** (await contractInstance.decimals().call())

      return { decimals }
    } catch (error) {
      return { error: error.message }
    }
  }

  // get balance of trc20 (in decimals)
  const getBalanceTRC20 = async (address, TOKEN_ADDRESS) => {
    try {
      address = address.toString().trim()
      tronWeb.setAddress(address)

      const contractInstance = await tronWeb.contract().at(TOKEN_ADDRESS)
      const { decimals, error } = await getDecimals(address, TOKEN_ADDRESS)
      if (error) {
        return { error }
      }

      let balance = await contractInstance.balanceOf(address).call()
      balance = new BigNumber(balance.toString())
      // balance = balance.dividedBy(parseInt(decimals))
      
      return { balanceTRC20: balance.toString(), decimals}
    } catch (error) {
      return { error: error.message }
    }
  }

  // Check address recipient wallet
  const checkMainWallet = (address) => {
    address = address.toString().trim()
    if (tronWeb.isAddress(address)) {
      return { isValid: true }
    }

    return { error: "Address is in valid format." }
  }

  // Check fromAddress and toAddress
  const checkAddress = (fromAddress, toAddress, checkBoth = false) => {
    try {
      // Trim data
      fromAddress = fromAddress.toString().trim()
      toAddress = toAddress.toString().trim()

      const validFromAddress = tronWeb.isAddress(fromAddress)
      const validToAddress = tronWeb.isAddress(fromAddress)

      // Valid from address
      if (!validFromAddress) {
        return { error: "fromAddress is not valid." }
      }

      // Valid to address`
      if (checkBoth && !validToAddress) {
        return { error: "toAddress is not valid." }
      }

      // Same address
      if (checkBoth && fromAddress === toAddress) {
        return { error: "fromAddress can not be the same with toAddress" }
      }

      return { isValid: true }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Check transferAmount and balanceTRX
  const checkTransferTRX = function (balanceTRX, amountTRX) {
    // balanceTRX:  SUN
    // amountTRX:  SUN
    balanceTRX = parseInt(balanceTRX)
    amountTRX = parseInt(amountTRX)

    try {
      // Check balanceTRX === 0
      if (balanceTRX === 0) {
        return { error: "0 TRX" }
      }

      // Check amountTRX === 0
      if (amountTRX === 0) {
        return { error: "Can not transfer with amount of 0" }
      }

      // Check amount not greater than balance
      if (amountTRX > balanceTRX) {
        return { error: "Transfer amount exceeds account's balance (TRX)" }
      }

      return { isValid: true }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Check balanceTRX in [TRX]
  const checkBalanceTRX = (balanceTRX, checkLimit = false) => {
    // balanceTRX: [SUN]
    try {
      // Check balance === 0
      if (!checkLimit && balanceTRX === 0) {
        return { error: "Empty balance (TRX)", transactionFee: balanceTRX_limit }
      }

      // Check greater than balanceTRX limit
      if (checkLimit) {
        if( balanceTRX === 0 || balanceTRX < tronWeb.toSun(balanceTRX_limit)) {
          let transactionFee = new BigNumber(parseInt(tronWeb.toSun(balanceTRX_limit)) - parseInt(balanceTRX))
          return {
            error: `Below the balance limit of TRX - Limit: ${balanceTRX_limit}`,
            limit: balanceTRX_limit, // in [TRX]
            transactionFee: transactionFee.toString(),
          }
        }
      }

      return { isValid: true }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Check transferAmount and balanceTRC20
  const checkTransferTRC20 = (balanceTRC20, amountTRC20, balanceTRX) => {
    // balanceTRC20 [in decimals], amountTRC20 [in decimals], balanceTRX [in Sun]
    try {
      // Check balanceTRC20 === 0
      if (parseInt(balanceTRC20) === 0) {
        return { error: "0 balance (TRC20)" }
      }

      // Check amount !== 0
      if (parseInt(amountTRC20) === 0) {
        return { error: "Transfer amount can not be equal to 0" }
      }

      // Check amount > balanceTRC20
      if (parseInt(amountTRC20) > parseInt(balanceTRC20)) {
        return { error: "Transfer amount exceeds account's balance (TRC20)" }
      }

      // Check balanceTRX != 0 && >= balanceTRX_limit
      const { error: balanceTRXErr, limit, transactionFee } = checkBalanceTRX(balanceTRX, true)
      if (balanceTRXErr) {
        return { error: balanceTRXErr, limit, transactionFee, amount: amountTRC20 }
      }

      return { isValid: true, amount: amountTRC20 }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Send TRX
  const sendTRX = async function (from, toAddress, amount) {
    // amount: in [SUN]
    try {
      // Trim data
      from.address = from.address.toString().trim()
      from.privateKey = from.privateKey.toString().trim()
      toAddress = toAddress.toString().trim()

      const tradeobj = await tronWeb.transactionBuilder.sendTrx(
        toAddress,
        amount.toString(),
        from.address,
        1
      )

      const signedtxn = await tronWeb.trx.sign(tradeobj, from.privateKey)
      const receipt = await tronWeb.trx.sendRawTransaction(signedtxn)

      return {
        txHash: receipt.txid,
      }
    } catch (error) {
      if (error === 'Private key does not match address in transaction') {
        return { error: "Sender's private key is not valid" }
      }
      return { error: error.message }
    }
  }

  // Send TRC20
  const sendTRC20 = async (from, toAddress, transferAmountTRC20, TOKEN_ADDRESS) => {
    // transferAmount: in (amount * decimals)
    try {
      // Trim data
      from.address = from.address.toString().trim()
      from.privateKey = from.privateKey.toString().trim()
      toAddress = toAddress.toString().trim()
            
      const amount = new BigNumber(transferAmountTRC20.toString())
      
      BigNumber.config({ EXPONENTIAL_AT: 40 })
      const parameter = [
        { type: "address", value: toAddress },
        { type: "uint256", value: amount.toString() },
      ]
      const options = {
        feeLimit: tronWeb.toSun(max_feeLimit),
        callValue: 0,
      }

      const transaction = await tronWeb.transactionBuilder.triggerSmartContract(
        TOKEN_ADDRESS,
        "transfer(address,uint256)",
        options,
        parameter,
        from.address
      )

      const signTx = await tronWeb.trx.sign(transaction.transaction, from.privateKey)

      const receipt = await tronWeb.trx.sendRawTransaction(signTx)
      return {
        txHash: receipt.txid,
        code: receipt.code
      }
    } catch (error) {
      return { error: error.message ? error.message : error }
    }
  }

  // Get transaction info by ID
  const getTxInfo = async (transactionId) => {
    try {
      const transInfo = await tronWeb.trx.getTransaction(transactionId)
      if (transInfo.ret && transInfo.ret[0].contractRet === "SUCCESS") {
        return { done: true }
      }
      if (transInfo.ret && transInfo.ret[0].contractRet === "OUT_OF_ENERGY") {
        return { done: false, error: "OUT_OF_ENERGY" }
      }
      if (transInfo.ret && transInfo.ret[0].contractRet === "FAILED") {
        return { done: false, error: "FAILED" }
      }
      // Pending
      return { done: false }
    } catch (error) {
      // transaction was not sent to the network
      if (error === "Transaction not found") {
        return { done: false }
      }
      return { done: false, error: error.message }
    }
  }

  // create accounts with delay
  const createAccounts = function (quantity = 0) {
    return new Promise((resolve) => {
      let accounts = []

      if(quantity > 0) {
        const createInterval = setInterval(() => {
          if (accounts.length === quantity) {
            clearInterval(createInterval)
            return resolve(accounts)
          }

          tronWeb.createAccount().then((acc) => {
            accounts.push({
              address: acc.address.base58,
              privateKey: acc.privateKey,
              active: 'FALSE'
            })
          })
        }, 1)
      }

    })
  }

  // Validation clone wallets from file
  const isActive = async function (address) {
    try {
      address = address.toString().trim()
      const wallet = await tronWeb.trx.getAccount(address)
      if (wallet && Object.keys(wallet).length === 0 && wallet.constructor === Object) {
        return false
      }

      return true
    } catch (error) {
      return false
    }
  }

  // Convert from decimals to token
  const convertToToken = function (amountToken, decimals) {
    let convertAmount = new BigNumber(amountToken.toString())
    convertAmount = convertAmount.dividedBy(parseInt(decimals))
    return { convertAmount }
  }

  // Convert from decimals to token
  const convertToDecimals = function (amountToken, decimals) {
    let convertAmount = new BigNumber(amountToken.toString())
    convertAmount = convertAmount.multipliedBy(parseInt(decimals))
    return { convertAmount }
  }
  
  return (
    <TronContext.Provider
      value={{
        tronWeb,
        unit,
        setMainWallet,
        getBalanceTRX,
        checkBalanceTRX,
        checkAddress,
        checkMainWallet,
        checkTransferTRX,
        checkTransferTRC20,
        getBalanceTRC20,
        getDecimals,
        sendTRX,
        sendTRC20,
        createAccounts,
        isActive,
        getTxInfo,
        convertToToken,
        convertToDecimals,
      }}
    >
      {children}
    </TronContext.Provider>
  )
}

export const useTronContext = function () {
  return useContext(TronContext)
}
