import { useState, useEffect, useRef } from "react"
import Logger from "./Logger"
import { useGlobalContext } from "../../../context/global"
import { useEthereumContext } from "../../../context/ethereum"

export default function ETHLogger({ LOCAL_STORAGE_NAME, setStage, collect, stage, getBalance, spread }) {
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState("")
  const [done, setDone] = useState(false)
  const [walletList] = useState(() => {
    const localStrData = localStorage.getItem(LOCAL_STORAGE_NAME)
    const walletList = JSON.parse(localStrData)
    walletList.splice(0, 1)
    if (localStrData) {
      const wallets = walletList.map((item) => {
        return { address: item.data[0], privateKey: item.data[1], amount: item.data[2] }
      })

      return wallets
    }

    return []
  })
  const { ETH_STAGES } = useGlobalContext()
  const {web3} = useEthereumContext()
  const LoggerDiv = useRef()

  // Append message
  useEffect(() => {
    if (message) {
      setMessages([...messages, message])
      const el = LoggerDiv.current
      el.scrollTop = el.scrollHeight
    }
  }, [message])

  useEffect(() => {
    // ======COLLECT [ETH]
    if (stage === ETH_STAGES.LOGGER) {
      async function collectETH() {
        const collectings = Promise.all(
          walletList.map(async (wallet, index) => {
            return new Promise(async (resolve) => {
                return collect(wallet, setMessage).then(r => {
                  if (r.error) {
                    return resolve({...r})
                  }
                  
                  r.wallet.balanceETH = (r.wallet.balanceETH) && web3.utils.fromWei(r.wallet.balanceETH.toString(), 'ether')
                  return resolve(r.wallet)
                }).catch(err => {
                  return resolve(err)
                })
            }
            )
          })
        )

        return collectings
      }
      // Save to localStorage
      collectETH().then((result) => {
        localStorage.setItem(`${LOCAL_STORAGE_NAME}-result`, JSON.stringify(result))
        setDone(true)
        setStage(ETH_STAGES.RESULT)
      })
    }
    
    // ======GET BALANCE [ETH]
    if(stage === ETH_STAGES.BALANCE) {
      async function getBalances() {
        const walletsWithBal = Promise.all(
          walletList.map(async (wallet, index) => {
            return new Promise(async (resolve) => {
              // setTimeout(async () => {
                const { balanceETH, error } = await getBalance(wallet.address)
                if (error ) {
                  setMessage(`${wallet.address} --- Failed to get balance`)
                  return resolve({ ...wallet, error })
                }
                // Done
                setMessage(`${wallet.address} --- ${balanceETH} ~ ETH`)
                return resolve({ ...wallet, balanceETH })
              // }, index * 700)
            }
            )
          })
        )
  
        return walletsWithBal
      }
  
      // Save to localStorage
      getBalances().then((result) => {
        localStorage.setItem(`${LOCAL_STORAGE_NAME}-balance`, JSON.stringify(result))
        setDone(true)
        setStage(ETH_STAGES.RESULT_BALANCE)
      })
    }

    // ======SPREAD [ETH]
    if (stage === ETH_STAGES.SPREAD_LOGGER) {
      async function spreadETH() {
        const spreadings = Promise.all(
          walletList.map(async (wallet, index) => {
            return new Promise(async (resolve) => {
                return spread(wallet, setMessage, index).then(r => {
                  if (r.error) {
                    return resolve({...r})
                  }
                  
                  r.wallet.balanceETH = (r.wallet.balanceETH) && web3.utils.fromWei(r.wallet.balanceETH.toString(), 'ether')
                  return resolve(r.wallet)
                }).catch(err => {
                  return resolve(err)
                })
            }
            )
          })
        )

        return spreadings
      }
      // Save to localStorage
      spreadETH().then((result) => {
        localStorage.setItem(`${LOCAL_STORAGE_NAME}-result`, JSON.stringify(result))
        setTimeout(() => {
          setDone(true)
          setStage(ETH_STAGES.RESULT)
        }, 5000)
      })
    }
    
  }, [])

  return (
    <div style={{ overflowY: "scroll", maxHeight: "80vh" }} ref={LoggerDiv}>
      {!done && (
        <>
          <Logger messages={messages} />
          <div className="text-center">
            <div
              className="spinner-border"
              role="status"
              style={{ width: "3rem", height: "3rem" }}
            ></div>
          </div>
        </>
      )}
    </div>
  )
}
