import { useState, useEffect, useRef } from "react"
import { useGlobalContext } from "../../../context/global"
import Logger from "./Logger"

export default function TRC20Logger({
  LOCAL_STORAGE_NAME, setStage, stage, getBalance, token, collect, spread, randomSpread, maxRandomNumb, setMaxRandomNumb}) {
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState("")
  const [done, setDone] = useState(false)
  const [walletList, setWalletList] = useState(() => {
    const localStrData = localStorage.getItem(LOCAL_STORAGE_NAME)
    const walletList =JSON.parse(localStrData)
    walletList.splice(0,1)
    if (localStrData) {
      const wallets = walletList.map((item) => {
        return { address: item.data[0], privateKey: item.data[1], amount: item.data[2] }
      })

      return wallets
    }

    return []
  })
  const { TRC20_STAGES } = useGlobalContext()
  const LoggerDiv = useRef()

  // Append message
  useEffect(() => {
    if (message)  {
      setMessages([...messages, message])
      const el = LoggerDiv.current
      el.scrollTop = el.scrollHeight
    }
  }, [message])

  useEffect(() => {
    // ======COLLECT [TRC20]
    if (stage === TRC20_STAGES.LOGGER) {
      async function collectTRC20() {
        const collectings = Promise.all(
          walletList.map(async (wallet, index) => {
            return new Promise((resolve) =>
              setTimeout(async () => {
                // return collect(wallet, setMessage).then(r => {
                //   const w = {...r}
                //   resolve(w)
                // }).catch(e => {resolve(e)}
                // )
                const w = await collect(wallet, setMessage, index)
                return resolve(w)
              }, index * 2000)
            )
          })
        )

        return collectings
      }
  
      // Save to localStorage
      collectTRC20().then((result) => {
        localStorage.setItem(`${LOCAL_STORAGE_NAME}-result`, JSON.stringify(result))
        setTimeout(() => {
          setDone(true)
          setStage(TRC20_STAGES.RESULT)
        }, 5000)
      })
    } 

    // ======SPREAD [TRC20]
    if (stage === TRC20_STAGES.SPREAD_LOGGER) {
      async function spreadTRC20() {
        const spreadings = Promise.all(
          walletList.map(async (wallet, index) => {
            return new Promise((resolve) =>
              setTimeout(async () => {
              //   return spread(wallet, setMessage).then(r => {
              //     resolve({...r})
              //   }).catch(e => {resolve(e)}
              //   )
              const w = await spread(wallet, setMessage)
              return resolve(w)
              }, index * 3900)
            )
          })
        )
  
        return spreadings
      }
  
      // Save to localStorage
      spreadTRC20().then((result) => {
        localStorage.setItem(`${LOCAL_STORAGE_NAME}-result`, JSON.stringify(result))
        setTimeout(() => {
          setDone(true)
          setStage(TRC20_STAGES.RESULT)
        }, 5000)
      })
    } 

    // ======RANDOM SPREAD [TRC20]
    if (stage === TRC20_STAGES.RANDOM_SPREAD_LOGGER) {
      async function randomSpreadTRC20() {
        let maxAmount = maxRandomNumb
        const randomSpreadings = Promise.all(
          walletList.map(async (wallet, index) => {
            return new Promise((resolve) =>
              setTimeout(() => {
                return randomSpread(wallet, setMessage, maxAmount).then(r => {
                  // setMaxRandomNumb(r.maxRandomNumb)
                  console.log(r.maxRandomNumb);
                  maxAmount = r.maxRandomNumb
                  resolve({...r})
                }).catch(e => {resolve(e)}
                )
              }, index * 4000)
            )
          })
        )
  
        return randomSpreadings
      }
  
      // Save to localStorage
      randomSpreadTRC20().then((result) => {
        localStorage.setItem(`${LOCAL_STORAGE_NAME}-result`, JSON.stringify(result))
        setTimeout(() => {
          setDone(true)
          setStage(TRC20_STAGES.RESULT)
        }, 5000)
      })
    }
  
    // ======GET BALANCE [TRC20]
    if(stage === TRC20_STAGES.BALANCE) {
      async function getBalances() {
      const walletsWithBal = Promise.all(
        walletList.map(async (wallet, index) => {
          return new Promise((resolve) =>
            setTimeout(async () => {
              const { balanceTRC20, error } = await getBalance(wallet.address)
              
              if (error ) {
                setMessage(`${wallet.address} --- Failed to get balance`)
                return resolve({ ...wallet, error })
              }
              // Done
              setMessage(`${wallet.address} --- ${balanceTRC20} ~ ${token.symbol}`)
              return resolve({ ...wallet, balanceTRC20 })
            }, index * 700)
          )
        })
      )

      return walletsWithBal
    }
  
      // Save to localStorage
      getBalances().then((result) => {
        localStorage.setItem(`${LOCAL_STORAGE_NAME}-balance`, JSON.stringify(result))
        setDone(true)
        setStage(TRC20_STAGES.RESULT_BALANCE)
      })
    }
  }, [])

  return (
    <div style={{ overflowY: "scroll", maxHeight: "80vh" }} ref={LoggerDiv}>
      {!done && (
        <>
          <Logger messages={messages} />
          <div className="text-center">
            <div
              className="spinner-border"
              role="status"
              style={{ width: "3rem", height: "3rem" }}
            ></div>
          </div>
        </>
      )}
    </div>
  )
}
