import { Row, Col, Card } from "react-bootstrap"
import { useState, useEffect } from "react"
import { useGlobalContext } from "../context/global"
import { useTronContext } from "../context/tron"
import Loading from "../components/Loading"
import FileDrop from "../components/Stage/FileDrop/FileDrop"
import TokenWallet from "../components/Stage/Wallet/TokenWallet"
import SpreadWallet from "../components/Stage/Wallet/SpreadWallet"
import RandomSpreadWallet from "../components/Stage/Wallet/RandomSpread"
import TRC20Table from "../components/Stage/DataTable/TRC20Table"
import TRC20Logger from "../components/Stage/Logger/TRC20Logger"

import BigNumber from "bignumber.js"
const LOCAL_STORAGE_NAME = "TRC20-Wallets"

export default function TRC20() {
  const { isLoading, setIsLoading, currentPath, TRC20_STAGES } = useGlobalContext()
  const [recipientAddr, setRecipientAddr] = useState("")
  const [providerAddr, setProviderAddr] = useState("")
  const [providerPK, setProviderPK] = useState("")
  const [spreaderAddr, setSpreaderAddr] = useState("")
  const [spreaderPK, setSpreaderPK] = useState("")
  const [stage, setStage] = useState(TRC20_STAGES.DROP_FILE)
  const [token, setToken] = useState()
  const [amountToSpread, setAmountToSpread] = useState(0)
  const {
    tronWeb,
    getBalanceTRC20,
    getBalanceTRX,
    checkAddress,
    checkTransferTRC20,
    sendTRC20,
    checkMainWallet,
    checkBalanceTRX,
    sendTRX,
    getTxInfo,
    isActive,
    convertToToken,
    convertToDecimals,
  } = useTronContext()
  const [minRandomNumb, setMinRandomNumb] = useState(100)
  const [maxRandomNumb, setMaxRandomNumb] = useState(200)
  const [randomWithAccumulate, setRandomWithAccumulate] = useState(false)

  useEffect(() => {
    setIsLoading(false)
    const walletList = localStorage.getItem(LOCAL_STORAGE_NAME)
    if (walletList) {
      setStage(TRC20_STAGES.DATA_TABLE)
    } else {
      setStage(TRC20_STAGES.DROP_FILE)
    }
  }, [])

  // Validate provider wallet
  const validateProvider = async function () {
    const wallet = { address: providerAddr, privateKey: providerPK }
    try {
      // Check address
      const { isValid: validAddress, error } = checkAddress(providerAddr)
      if (!validAddress) {
        return { ...wallet, error }
      }

      // get balanceTRX
      const { balanceTRX, error: balTRXError } = await getBalanceTRX(providerAddr)
      if (balTRXError) {
        return { ...wallet, error: balTRXError }
      }

      // check balanceTRX ( !== 0 && > fee limit)
      const { error: balanceTRXError } = checkBalanceTRX(balanceTRX, true)
      if (balanceTRXError) {
        return { ...wallet, error: balanceTRXError }
      }

      return { wallet }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Validation clone wallet's data from file
  const validation = async function (wallet) {
    try {
      // Trim data
      wallet.address = wallet.address.toString()
      wallet.privateKey = wallet.privateKey.toString()

      // Check address
      const { error: addressError } = await checkAddress(wallet.address, providerAddr)
      if (addressError) {
        return { ...wallet, error: addressError }
      }

      // Get balanceTRC20
      const {
        balanceTRC20,
        error: getBLError,
        decimals,
      } = await getBalanceTRC20(wallet.address, token.address)
      if (getBLError) {
        return { ...wallet, error: getBLError }
      }
      wallet.balanceTRC20 = balanceTRC20

      // Get balanceTRX
      const { balanceTRX, error: balanceTRXErr } = await getBalanceTRX(wallet.address)
      if (balanceTRXErr) {
        return { ...wallet, error: balanceTRXErr }
      }

      // Convert amount ("" => balanceTRC20)
      if (!wallet.amount) {
        wallet.amountTRC20 = balanceTRC20
        wallet.amount = convertToToken(parseInt(balanceTRC20), parseInt(decimals)).convertAmount
      } else {
        wallet.amountTRC20 = convertToDecimals(
          parseInt(wallet.amount),
          parseInt(decimals)
        ).convertAmount
      }

      // check transfer trc20
      const {
        error: transferErr,
        limit,
        transactionFee,
      } = checkTransferTRC20(balanceTRC20, wallet.amountTRC20, balanceTRX)
      if (transferErr) {
        return { validWallet: { ...wallet, limit, transactionFee }, error: transferErr, decimals }
      }

      return {
        validWallet: {
          ...wallet,
        },
        decimals,
      }
    } catch (error) {
      return { error }
    }
  }

  // Get Balance TRC20
  const getBalance = async function (address) {
    try {
      address = address.toString().trim()
      const { balanceTRC20, decimals, error } = await getBalanceTRC20(address, token.address)
      if (error) {
        return { error }
      }
      if (isNaN(balanceTRC20)) {
        return { error: `TOKEN is not on the network` }
      }
      let balance = new BigNumber(balanceTRC20)
      balance = BigNumber(balanceTRC20).dividedBy(parseInt(decimals))

      return { balanceTRC20: balance }
    } catch (error) {
      return { error }
    }
  }

  // Provide TRX
  const provideFee = async (from, toAddress, fee, index) => {
    return new Promise(async (resolve) => {
      try {
        // Check Provider's balance
        // get balanceTRX
        const { balanceTRX, error: balTRXError } = await getBalanceTRX(from.address)
        if (balTRXError) {
          return resolve({ error: balTRXError })
        }

        // // check balanceTRX ( !== 0 && > fee limit)
        if (parseInt(balanceTRX) === 0 || parseInt(balanceTRX) < parseInt(fee)) {
          return resolve({
            error: `Provider\'s balance is not enough - ${tronWeb.fromSun(balanceTRX)}`,
          })
        }

        const { txHash, error } = await sendTRX(from, toAddress, fee)
        if (error) {
          return resolve({ error: error })
        } else if (txHash) {
          const infoInterval = setInterval(() => {
            // Get transaction info
            getTxInfo(txHash).then((info) => {
              const { done, error: infoErr } = info
              // Success
              if (done) {
                clearInterval(infoInterval)
                return resolve({ provided: true })
              }

              if (infoErr && !done) {
                // Failed
                clearInterval(infoInterval)
                return resolve({ error: infoErr })
              }
            })
          }, 5000 + 1000 * index)
        } else {
          return resolve({ error: "Failed to provide transaction fee" })
        }
      } catch (error) {
        return resolve({ error })
      }
    })
  }

  // Collect TRC20
  const collect = async (wallet, setMessage, index) => {
    try {
      const { validWallet, error: transferErr, decimals } = await validation(wallet)
      if (wallet.address.toLowerCase() === recipientAddr.toLowerCase()) {
        return {...validWallet, error: `Same address [${wallet.address}]`}
      }
      validWallet.balanceTRC20 = convertToToken(
        parseInt(validWallet.balanceTRC20),
        decimals
      ).convertAmount
      let enoughFee = validWallet.transactionFee ? false : true
      // transfer Error
      if (transferErr && !validWallet.transactionFee) {
        setMessage(`Collecting from ${wallet.address}... ======> Error: [${transferErr}]`)
        return { ...validWallet, error: transferErr }
      }

      if (!enoughFee) {
        // provide transaction fee
        const { provided, error: provideErr } = await provideFee(
          {
            address: providerAddr,
            privateKey: providerPK,
          },
          validWallet.address,
          validWallet.transactionFee,
          index
        )
        if (provideErr) {
          setMessage(`Collecting from ${wallet.address}... ======> Error: [${provideErr}]`)
          return { ...validWallet, error: provideErr }
        }
        if (provided) {
          enoughFee = true
        }
      }

      if (enoughFee) {
        // Collect TRC20 from sub wallets
        const { txHash, error: txHashError } = await sendTRC20(
          validWallet,
          recipientAddr,
          validWallet.amountTRC20,
          token.address
        )

        if (txHash) {
          validWallet.txHash = txHash
          setMessage(`Collecting from ${wallet.address}... ======> DONE [Hash: ${txHash}]`)
          return {
            ...validWallet,
            // balanceTRC20: convertToToken(parseInt(validWallet.balanceTRC20), decimals)
            //   .convertAmount,
          }
        }

        if (txHashError) {
          setMessage(`Collecting from ${wallet.address}... ======> Error: [${txHashError}]`)
          return {
            ...wallet,
            error: txHashError,
          }
        }
      }
    } catch (error) {
      return { ...wallet, error: error.message }
    }
  }

  // Check spreader wallet before spreading
  const checkSpreaderWallet = async function () {
    try {
      // Check address format
      const isValidAddress = tronWeb.isAddress(spreaderAddr)
      if (!isValidAddress) {
        return [null, `Invalid format [${spreaderAddr}]`]
      }

      // Get balance TRX
      const { balanceTRX, error: balTRX_Err } = await getBalanceTRX(spreaderAddr)
      if (balTRX_Err) {
        return [null, balTRX_Err]
      }

      // Check balance TRX !== 0
      const { error: checkTRX_Err } = await checkBalanceTRX(balanceTRX, true)
      if (checkTRX_Err) {
        return [null, checkTRX_Err]
      }

      // Get balance TRC20
      const {
        balanceTRC20,
        error: balTRC20_Err,
        decimals,
      } = await getBalanceTRC20(spreaderAddr, token.address)
      if (balTRC20_Err) {
        return [null, balTRC20_Err]
      }

      // Check balance TRC20 !== 0
      if (parseInt(balanceTRC20) === 0) {
        return [null, `Empty balance (TRC20)`]
      }

      // Convert amountToSpread
      const convertSpreadAmount = convertToDecimals(amountToSpread, decimals).convertAmount

      // Check amountToSpread !== 0
      if (parseInt(amountToSpread) === 0) {
        return [null, `Can not spread with 0 amount.`]
      }

      // Check convertSpreadAmount > balance TRC20
      // Make sure at least spreader can spread 1 wallet
      if (convertSpreadAmount > parseInt(balanceTRC20)) {
        return [
          null,
          `Insufficient balance TRC20 - Balance: ${
            convertToToken(parseInt(balanceTRC20), decimals).convertAmount
          }`,
        ]
      }

      return [true, null]
    } catch (error) {
      return [null, error.message ? error.message : error]
    }
  }

  // Check spreader wallet before spreading
  const checkRandomSpreadWallet = async function () {
    try {
      // Check address format
      const isValidAddress = tronWeb.isAddress(spreaderAddr)
      if (!isValidAddress) {
        return { error: `Invalid format [${spreaderAddr}]` }
      }

      // Get balance TRX
      const { balanceTRX, error: balTRX_Err } = await getBalanceTRX(spreaderAddr)
      if (balTRX_Err) {
        return { error: balTRX_Err }
      }

      // Check balance TRX !== 0
      const { error: checkTRX_Err } = await checkBalanceTRX(balanceTRX, true)
      if (checkTRX_Err) {
        return { error: checkTRX_Err }
      }

      // Get balance TRC20
      const {
        balanceTRC20,
        error: balTRC20_Err,
        decimals,
      } = await getBalanceTRC20(spreaderAddr, token.address)
      if (balTRC20_Err) {
        return { error: balTRC20_Err }
      }

      // Check balance TRC20 !== 0
      if (parseInt(balanceTRC20) === 0) {
        return { error: `Empty balance (TRC20)` }
      }

      // Convert amountToSpread
      const convertMinAmount = convertToDecimals(minRandomNumb, decimals).convertAmount
      const convertMaxAmount = convertToDecimals(maxRandomNumb, decimals).convertAmount

      // Check convertMinAmount !== 0
      if (parseInt(convertMinAmount) === 0) {
        return { error: `Can not spread with 0 amount.` }
      }

      // Check convertMaxAmount !== 0
      if (parseInt(convertMaxAmount) === 0) {
        return { error: `Can not spread with 0 amount.` }
      }

      // Check convertSpreadAmount > balance TRC20
      // Make sure at least spreader can spread 1 wallet
      if (convertMaxAmount > parseInt(balanceTRC20)) {
        return {
          error: `Insufficient balance TRC20 - Balance: ${
            convertToToken(parseInt(balanceTRC20), decimals).convertAmount
          }`,
        }
      }

      return { isValid: true }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Validate spreader each spreading
  const validateSpreader = async function (toAddress) {
    try {
      // Trim data
      toAddress = toAddress.toString().trim()

      // Check valid address
      if (!tronWeb.isAddress(toAddress)) {
        return { error: `Invalid format [${toAddress}]` }
      }

      // Check same address
      if (toAddress === spreaderAddr) {
        return { error: `Same address [${toAddress}]` }
      }

      // Check toWallet active
      const active = await isActive(toAddress)
      if (!active) {
        return { error: `Inactive account` }
      }

      // Get balance
      const { balanceTRX, error: balTRX_Err } = await getBalanceTRX(spreaderAddr)
      if (balTRX_Err) {
        return { error: balTRX_Err }
      }

      // Check balance !== 0 && > fee_limit
      const { error: checkBalTRX_Err } = await checkBalanceTRX(balanceTRX, true)
      if (checkBalTRX_Err) {
        return { error: checkBalTRX_Err }
      }

      // Get balance TRC20
      const {
        balanceTRC20,
        error: balTRC20_Err,
        decimals,
      } = await getBalanceTRC20(spreaderAddr, token.address)
      if (balTRC20_Err) {
        return { error: balTRC20_Err }
      }

      // Check balance TRC20 !== 0
      if (parseInt(balanceTRC20) === 0) {
        return { error: `Empty balance (TRC20)` }
      }

      // Convert amountToSpread to decimals
      const convertSpreadAmount = convertToDecimals(amountToSpread, decimals).convertAmount

      // Check balance TRC20 >= spreadAmountToDecimals
      if (parseInt(balanceTRC20) < parseInt(convertSpreadAmount)) {
        return { error: `Insufficient balance ${token.symbol}` }
      }

      return { isValid: true, convertSpreadAmount, decimals }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Validate
  const spread = async function (wallet, setMessage) {
    try {
      const { error: transferErr, convertSpreadAmount, decimals } = await validateSpreader(wallet.address)
      wallet.amount = parseInt(amountToSpread)
      // transfer Error
      if (transferErr) {
        setMessage(`Spreading to ${wallet.address}... ======> Error: [${transferErr}]`)
        return { ...wallet, error: transferErr }
      }

      // Spreading TRC20 to sub wallets
      const { txHash, error: txHashError } = await sendTRC20(
        { address: spreaderAddr, privateKey: spreaderPK },
        wallet.address,
        parseInt(convertSpreadAmount),
        token.address
      )
      if (txHashError) {
        setMessage(`Spreading to ${wallet.address}... ======> Error: [${txHashError}]`)
        return { ...wallet, error: txHashError }
      }

      if (txHash) {
        wallet.txHash = txHash
        wallet.balanceTRC20 = convertToToken(parseInt(convertSpreadAmount), decimals).convertAmount
        setMessage(`Spreading to ${wallet.address}... ======> DONE [Hash: ${txHash}]`)
        return {
          ...wallet,
        }
      }
    } catch (error) {
      return { ...wallet, error: error.message }
    }
  }

  // Validate spreader each spreading
  const validateRandomSpreader = async function (toAddress, spreadAmount) {
    try {
      // Trim data
      toAddress = toAddress.toString().trim()

      // Check valid address
      if (!tronWeb.isAddress(toAddress)) {
        return { error: `Invalid format [${toAddress}]` }
      }

      // Check same address
      if (toAddress === spreaderAddr) {
        return { error: `Same address [${toAddress}]` }
      }

      // Check toWallet active
      const active = await isActive(toAddress)
      if (!active) {
        return { error: `Inactive account` }
      }

      // Get balance
      const { balanceTRX, error: balTRX_Err } = await getBalanceTRX(spreaderAddr)
      if (balTRX_Err) {
        return { error: balTRX_Err }
      }

      // Check balance !== 0 && > fee_limit
      const { error: checkBalTRX_Err } = await checkBalanceTRX(balanceTRX, true)
      if (checkBalTRX_Err) {
        return { error: checkBalTRX_Err }
      }

      // Get balance TRC20
      const {
        balanceTRC20,
        error: balTRC20_Err,
        decimals,
      } = await getBalanceTRC20(spreaderAddr, token.address)
      if (balTRC20_Err) {
        return { error: balTRC20_Err }
      }

      // Check balance TRC20 !== 0
      if (parseInt(balanceTRC20) === 0) {
        return { error: `Empty balance (TRC20)` }
      }

      // Convert spreadAmount to decimals
      const convertSpreadAmount = convertToDecimals(spreadAmount, decimals).convertAmount

      // Check balance TRC20 >= spreadAmountToDecimals
      if (parseInt(balanceTRC20) < parseInt(convertSpreadAmount)) {
        return { error: `Insufficient balance ${token.symbol}` }
      }

      return { isValid: true, convertSpreadAmount }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Generate random amount
  const generateRandom = function (maxAmount) {
    const randomNumb = Math.floor(Math.random() * (maxAmount - minRandomNumb) + minRandomNumb)
    return randomNumb
  }

  // Spread with random amount
  const randomSpread = async function (wallet, setMessage, maxAmount) {
    try {
      const randomAmount = generateRandom(maxAmount)
      const { error: transferErr, convertSpreadAmount } = await validateRandomSpreader(
        wallet.address,
        randomAmount
      )
      wallet.amount = parseInt(randomAmount)
      // Transfer Error
      if (transferErr) {
        setMessage(`Spreading to ${wallet.address}... ======> Error: [${transferErr}]`)
        return { ...wallet, error: transferErr }
      }

      // let spreadAmount = (isLastItem)
      // ? convertToDecimals(limitRandomSpread - totalRandomNumb).convertAmount
      // : convertSpreadAmount

      // Spreading TRC20 to sub wallets
      const { txHash, error: txHashError } = await sendTRC20(
        { address: spreaderAddr, privateKey: spreaderPK },
        wallet.address,
        convertSpreadAmount.toString(),
        token.address
      )
      if (txHashError) {
        setMessage(
          `Spreading ${randomAmount} to ${wallet.address}... ======> Error: [${txHashError}]`
        )
        return { ...wallet, error: txHashError }
      }

      if (txHash) {
        wallet.txHash = txHash
        // Update total random spread amount
        const newMaxRandomNumb = !randomWithAccumulate
          ? maxRandomNumb
          : maxAmount - randomAmount + maxAmount
        setMessage(
          `Spreading ${randomAmount} to ${wallet.address}... ======> DONE [Hash: ${txHash}]`
        )
        return {
          ...wallet,
          maxRandomNumb: newMaxRandomNumb,
        }
      }

      return { ...wallet }
    } catch (error) {
      return { ...wallet, error: error.message }
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className="px-1 mx-3">
        <Row className="my-3">
          <Col>
            <Card>
              <h5 className="card-header">
                Đồng <strong>{currentPath.toUpperCase()}</strong>
              </h5>
              <div className="card-body p-3">
                {/* Stage 1 */}
                {stage === TRC20_STAGES.DROP_FILE && (
                  <FileDrop
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    STAGES={TRC20_STAGES}
                    setStage={setStage}
                  />
                )}

                {/* Stage 2 || 5 || 7 */}
                {(stage === TRC20_STAGES.DATA_TABLE ||
                  stage === TRC20_STAGES.RESULT ||
                  stage === TRC20_STAGES.RESULT_BALANCE) && (
                  <TRC20Table
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    setStage={setStage}
                    stage={stage}
                    token={token}
                    setToken={setToken}
                  />
                )}

                {/* Stage 3 */}
                {stage === TRC20_STAGES.TRANSACTION_WALLET && (
                  <TokenWallet
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    stage={stage}
                    setStage={setStage}
                    recipientAddr={recipientAddr}
                    setRecipientAddr={setRecipientAddr}
                    providerAddr={providerAddr}
                    setProviderAddr={setProviderAddr}
                    providerPK={providerPK}
                    setProviderPK={setProviderPK}
                    checkMainWallet={checkMainWallet}
                    validateProvider={validateProvider}
                    setToken={setToken}
                  />
                )}

                {/* Stage 3 */}
                {stage === TRC20_STAGES.RANDOM_SPREAD_WALLET && (
                  <RandomSpreadWallet
                    token={token}
                    setStage={setStage}
                    STAGES={TRC20_STAGES}
                    spreaderAddr={spreaderAddr}
                    setSpreaderAddr={setSpreaderAddr}
                    spreaderPK={spreaderPK}
                    setSpreaderPK={setSpreaderPK}
                    validateRandomSpreadWallet={checkRandomSpreadWallet}
                    minRandomNumb={minRandomNumb}
                    setMinRandomNumb={setMinRandomNumb}
                    maxRandomNumb={maxRandomNumb}
                    setMaxRandomNumb={setMaxRandomNumb}
                    randomWithAccumulate={randomWithAccumulate}
                    setRandomWithAccumulate={setRandomWithAccumulate}
                    nextStage={TRC20_STAGES.RANDOM_SPREAD_LOGGER}
                    prevStage={TRC20_STAGES.DATA_TABLE}
                  />
                )}

                {/* Stage 3 */}
                {stage === TRC20_STAGES.SPREAD_WALLET && (
                  <SpreadWallet
                    token={token}
                    setStage={setStage}
                    STAGES={TRC20_STAGES}
                    spreaderAddr={spreaderAddr}
                    setSpreaderAddr={setSpreaderAddr}
                    spreaderPK={spreaderPK}
                    setSpreaderPK={setSpreaderPK}
                    validateSpreadWallet={checkSpreaderWallet}
                    amountToSpread={amountToSpread}
                    setAmountToSpread={setAmountToSpread}
                    nextStage={TRC20_STAGES.SPREAD_LOGGER}
                    prevStage={TRC20_STAGES.DATA_TABLE}
                  />
                )}

                {/* Stage 4 || 6 */}
                {(stage === TRC20_STAGES.LOGGER ||
                  stage === TRC20_STAGES.BALANCE ||
                  stage === TRC20_STAGES.SPREAD_LOGGER ||
                  stage === TRC20_STAGES.RANDOM_SPREAD_LOGGER) && (
                  <TRC20Logger
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    stage={stage}
                    setStage={setStage}
                    token={token}
                    recipientAddr={recipientAddr}
                    validation={validation}
                    collect={collect}
                    getBalance={getBalance}
                    spread={spread}
                    randomSpread={randomSpread}
                    maxRandomNumb={maxRandomNumb}
                    setMaxRandomNumb={setMaxRandomNumb}
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
