import { useState, useEffect, useRef } from "react"
import Logger from "./Logger"
import { useGlobalContext } from "../../../context/global"

export default function ActivateLogger({
  LOCAL_STORAGE_NAME,
  setStage,
  spread,
  stage,
  getBalance,
  activate, token,
  trackTx
}) {
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState("")
  const [done, setDone] = useState(false)
  const { WALLETS_STAGES } = useGlobalContext()
  const LoggerDiv = useRef()

  // Append message
  useEffect(() => {
    if (message) {
      setMessages([...messages, message])
      const el = LoggerDiv.current
      el.scrollTop = el.scrollHeight
    }
  }, [message])

  useEffect(() => {
    // ======ACTIVATE ACCOUNTS 
    if (stage === WALLETS_STAGES.ACTIVATE) {
      async function activateAccounts() {
        const wallets = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_NAME}`))
        
        const activation = Promise.all(
          wallets.map(async (wallet, index) => {
            return new Promise((resolve) =>
              setTimeout(async () => {
                const result = await activate(wallet, setMessage)
                if (result.error) {
                  return resolve(result)
                }

                return resolve(result)
              }, index * 700)
            )
          })
        )

        return activation
      }
      // Save to localStorage
      activateAccounts().then((result) => {
        localStorage.setItem(`${LOCAL_STORAGE_NAME}-result`, JSON.stringify(result))
        localStorage.setItem(`${LOCAL_STORAGE_NAME}`, JSON.stringify(result))
        setTimeout(() => {
          setDone(true)
          setStage(WALLETS_STAGES.RESULT)
        }, 10000)
      })
    }

  }, [])

  return (
    <div style={{ overflowY: "scroll", maxHeight: "80vh" }} ref={LoggerDiv}>
      {!done && (
        <>
          <Logger messages={messages} />
          <div className="text-center">
            <div
              className="spinner-border"
              role="status"
              style={{ width: "3rem", height: "3rem" }}
            ></div>
          </div>
        </>
      )}
    </div>
  )
}
