import { applySnapshot, types } from 'mobx-state-tree'
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import BEP20_ABI from '../../constant/ABI/BEP20.json'

const ABI = {
	BEP20: BEP20_ABI,
}
const STAGES = {
	DROP_FILE: 1,
	DATA_TABLE: 2,
	TRANSACTION_WALLET: 3,
	LOGGER: 4,
	RESULT: 5,
	BALANCE: 6,
	RESULT_BALANCE: 7,
	SPREAD_WALLET: 8,
	SPREAD_LOGGER: 9,
}

BigNumber.config({ EXPONENTIAL_AT: 50 })

export const initBscModel = {
	MAIN_PROVIDER: 'https://bsc-dataseed1.binance.org/',
	TEST_PROVIDER: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
	LOCAL_STORAGE_NAME: 'BSC',
}
export const BscModel = types
	.model({
		NETWORK: types.string,
		MAIN_PROVIDER: types.string,
		TEST_PROVIDER: types.string,
		LOCAL_STORAGE_NAME: types.string,
		ABI: types.frozen(ABI),
		STAGES: types.frozen(STAGES),
	})
	.views((self) => ({
		web3() {
			const Provider =
				self.NETWORK === 'TEST' ? self.TEST_PROVIDER : self.MAIN_PROVIDER
			return new Web3(Provider)
		},
	}))
	.actions((self) => {
		const web3 = self.web3()

		return {
			setNetwork(_NETWORK) {
				applySnapshot(self, { ...self, NETWORK: _NETWORK })
			},

			// Convert to token unit
			convertToToken(_amountToken, _decimal) {
				return new BigNumber(_amountToken).dividedBy(10 ** parseInt(_decimal))
			},

			// Convert from decimal unit
			convertToDecimals(_amountToken, _decimal) {
				return new BigNumber(_amountToken).multipliedBy(
					10 ** parseInt(_decimal)
				)
			},

			getWalletFromPK(_privateKey) {
				try {
					if (!_privateKey) {
						return [null, 'Can not get wallet with null private key.']
					}

					const { address, privateKey } = web3.eth.accounts.privateKeyToAccount(
						_privateKey.toString().trim()
					)

					return [{ address, privateKey }, null]
				} catch (error) {
					return [null, 'Invalid Private Key.']
				}
			},

			checkAddress(_address) {
				if (!_address) {
					return [null, 'Address is undefined or null.']
				}

				const address = _address.toString().trim().toLowerCase()
				if (_address && !web3.utils.isAddress(address)) {
					return [null, 'Invalid address format.']
				}

				return [address, null]
			},

			// Check two address before making transactions
			checkAddressForTransferring(_fromAddress, _toAddress) {
				const [fromAddress, fromErr] = self.checkAddress(_fromAddress)
				const [toAddress, toErr] = self.checkAddress(_toAddress)

				if (fromErr) {
					return [null, fromErr]
				}

				if (toErr) {
					return [null, toErr]
				}

				// Check duplicate
				if (fromAddress === toAddress) {
					return [null, 'Same address.']
				}

				return [true, null]
			},

			// Get balance of Bnb
			async getBalanceBnb(_address) {
				try {
					const balanceBnb = await web3.eth.getBalance(_address)
					return [balanceBnb, null]
				} catch (error) {
					return [null, error.message ? error.message : error]
				}
			},

			// Get balance of Bep20
			async getBalanceBep20(_address, BEP20_TOKEN) {
				try {
					const contractInstance = new web3.eth.Contract(
						self.ABI.BEP20,
						BEP20_TOKEN.address
					)
					let balanceBep20 = await contractInstance.methods
						.balanceOf(_address)
						.call()

					balanceBep20 = balanceBep20.toString()
					return [balanceBep20, null]
				} catch (error) {
					return [null, error.message ? error.message : error]
				}
			},

			async calculateBEP20Fee(
				BEP20_TOKEN,
				fromAddress,
				toAddress,
				amountOfEther
			) {
				try {
					const contractInstance = new web3.eth.Contract(
						self.ABI.BEP20,
						BEP20_TOKEN.address
					)
					const amountOfWei = self.convertToDecimals(
						amountOfEther,
						BEP20_TOKEN.decimals
					)
					const gasFee = (await web3.eth.getGasPrice()) * 1.1
					// Calculate gas
					const estimateGas = await contractInstance.methods
						.transfer(toAddress, amountOfWei.toString())
						.estimateGas({ from: fromAddress, gas: 5_000_000 })

					const totalFee = new BigNumber(gasFee).multipliedBy(estimateGas)

					return [totalFee, null]
				} catch (error) {
					return [null, error.message ? error.message : error]
				}
			},

			async sendBnb(wallet, toAddress, amountOfEther, nonce) {
				const amountOfWei = web3.utils.toWei(amountOfEther.toString(), 'ether')
				const gasPrice = (await web3.eth.getGasPrice()) * 1.1
				const gas = 21000
				let _amount = amountOfWei
				const transferAmount = new BigNumber(amountOfWei).minus(
					gas * parseInt(gasPrice)
				)
				// Insufficient amount
				if (transferAmount.lt(0)) {
					return [null, 'Amount is too small, below the gas fee']
				}

				try {
					const [balanceBnb] = await self.getBalanceBnb(wallet.address)
					if (balanceBnb && new BigNumber(amountOfWei).eq(balanceBnb)) {
						_amount = transferAmount
					}

					const txObject = {
						from: wallet.address,
						to: toAddress.toString(),
						gasPrice,
						gas,
						value: _amount.toString(),
					}

					if (nonce) {
						txObject.nonce = web3.utils.toHex(nonce)
					}

					const signedTransaction = await web3.eth.accounts.signTransaction(
						txObject,
						wallet.privateKey
					)

					const receipt = await web3.eth.sendSignedTransaction(
						signedTransaction.rawTransaction
					)

					return [receipt, null]
				} catch (error) {
					return [null, error.message ? error.message : error]
				}
			},

			async sendBep20(wallet, toAddress, amountOfBEP20, BEP20_TOKEN, nonce) {
				try {
					const contractInstance = new web3.eth.Contract(
						self.ABI.BEP20,
						BEP20_TOKEN.address
					)
					// Convert amountERC20
					const amountOfWei = self.convertToDecimals(
						amountOfBEP20,
						BEP20_TOKEN.decimals
					)

					// Calculate transfer data
					const transferData = await contractInstance.methods
						.transfer(toAddress, amountOfWei.toString())
						.encodeABI()

					// Calculate gas
					const estimateGas = await contractInstance.methods
						.transfer(toAddress, amountOfWei.toString())
						.estimateGas({ from: wallet.address })

					// Gas fee
					const gasFee = (await web3.eth.getGasPrice()) * 1.1

					// Create transaction
					const txObject = {
						from: wallet.address,
						gasPrice: web3.utils.toHex(gasFee.toString()),
						gas: web3.utils.toHex(estimateGas.toString()), // Gas limit
						to: BEP20_TOKEN.address,
						value: '0', // in wei
						data: web3.utils.toHex(transferData),
					}

					if (nonce) {
						txObject.nonce = web3.utils.toHex(nonce)
					}

					// Sign transaction
					const signedTransaction = await web3.eth.accounts.signTransaction(
						txObject,
						wallet.privateKey
					)

					// Send signed transaction
					const receipt = await web3.eth.sendSignedTransaction(
						signedTransaction.rawTransaction
					)

					return [receipt, null]
				} catch (error) {
					return [null, error.message ? error.message : error]
				}
			},
		}
	})
