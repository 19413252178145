import { Form, FormLabel, FormGroup, FormControl } from "react-bootstrap"
import { useState } from "react"
import { useGlobalContext } from "../../../context/global"
import { FaArrowLeft, FaWallet } from "react-icons/fa"
import { useTronContext } from "../../../context/tron"
import { useEthereumContext } from "../../../context/ethereum"

export default function MainWallet({
  setStage,
  recipientAddr,
  setRecipientAddr,
  checkMainWallet,
  STAGES,
}) {
  const { ETH_STAGES } = useGlobalContext()
  const [isChecking, setIsChecking] = useState(false)
  const [error, setError] = useState("")

  const handleSubmit = function (e) {
    e.preventDefault()
    setError('')
    setIsChecking(true)
    const { walletAddress } = e.target.elements

    const [validRecipient, errorRecipient] = checkMainWallet(walletAddress.value)
    if (!validRecipient) {
      setError(errorRecipient)
    }

    if (validRecipient) {
      setStage(STAGES.LOGGER)
    }
    setIsChecking(false)
    // if (!web3.utils.isAddress(walletAddress.value)) {
    //   setError(`Invalid address [${walletAddress.value}]`)
    //   setIsChecking(false)
    // } else {
    //   setIsChecking(false)
    //   setStage(STAGES.LOGGER)
    // }
  }

  return (
    <>
      <small>
        <button
          className="btn btn-secondary py-1 px-2"
          onClick={(e) => setStage(ETH_STAGES.DATA_TABLE)}
        >
          <FaArrowLeft style={{ fontSize: "13px" }} />
        </button>
      </small>
      <Form onSubmit={handleSubmit} className="mt-4">
        <FormGroup>
          <FormLabel>
            <FaWallet className="mr-2" />
            Địa chỉ <span className="text-danger text-uppercase">ví tổng</span>:
          </FormLabel>
          <FormControl
            type="text"
            required
            name="walletAddress"
            onChange={(e) => setRecipientAddr(e.target.value.toString().trim())}
            value={recipientAddr}
          />
        </FormGroup>

        {/* Alert message */}
        <FormGroup>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </FormGroup>

        {/* Loader */}
        {isChecking && (
          <div className="text-center my-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        )}

        <FormGroup>
          <FormControl
            className="btn btn-primary"
            type="submit"
            value="Send"
            disabled={isChecking ? "disabled" : ""}
          />
        </FormGroup>
      </Form>
    </>
  )
}
