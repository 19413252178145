import { CSVDownloader, jsonToCSV } from "react-papaparse"
import { useState, useEffect } from "react"
import { FaFileDownload, FaTimes, FaWallet, FaMenorah } from "react-icons/fa"
import { ButtonGroup, Button, Dropdown } from "react-bootstrap"
import Table from "../../Table/Table"
import { useGlobalContext } from "../../../context/global"
import { BSC_TOKENS } from "../../../constant/bsc"
import { useRootStore } from "../../../context/mst/Root"
import { Balance_Columns, File_Columns, Result_Columns } from "../Columns/BscCol"

export default function BscTable({ LOCAL_STORAGE_NAME, stage, setStage, token, setToken }) {
	const { validateInputCSV } = useGlobalContext()
	// const { parseEtherResultDownload } = useEthereumContext()
	const {
		RootStore: { BscStore },
	} = useRootStore()
	const { STAGES } = BscStore
	const [error, setError] = useState("")
	const [showResult, setShowResult] = useState(false)
	const [showInput, setShowInput] = useState(false)
	const [walletList, setWalletList] = useState([])
	const [dataDownload, setDataDownload] = useState(JSON.parse("[]"))

	useEffect(() => {
		if (stage === STAGES.RESULT || STAGES.RESULT_BALANCE) {
			setShowInput(true)
			setShowResult(false)
		}
		if (stage === STAGES.DATA_TABLE || stage === STAGES.BALANCE) {
			setShowInput(false)
			setShowResult(true)
		}
	}, [])

	// Set data for table
	useEffect(() => {
		if (stage === STAGES.DATA_TABLE) {
			const walletData = validateInputCSV(LOCAL_STORAGE_NAME, setError)
			if (walletData.length !== 0) {
				setWalletList(walletData)
				setDataDownload(walletData)
				return
			}
		}

		if (stage === STAGES.RESULT) {
			const localStrData = localStorage.getItem(`${LOCAL_STORAGE_NAME}-result`)

			if (localStrData) {
				setWalletList(JSON.parse(localStrData))
				// setDataDownload(parseEtherResultDownload(JSON.parse(localStrData)))
				setDataDownload(JSON.parse(localStrData))
				return
			}
		}

		if (stage === STAGES.RESULT_BALANCE) {
			const localStrData = localStorage.getItem(`${LOCAL_STORAGE_NAME}-balance`)
			if (localStrData) {
				setWalletList(JSON.parse(localStrData))
				setDataDownload(JSON.parse(localStrData))
				return
			}
		}
	}, [stage])

	const handleOnRemoveFile = (data) => {
		localStorage.removeItem(LOCAL_STORAGE_NAME)
		localStorage.removeItem(`${LOCAL_STORAGE_NAME}-result`)
		setWalletList([])
		setStage(STAGES.DROP_FILE)
	}

	return (
		<>
			{error && <p className="text-danger">{error}</p>}

			<div className="d-flex justify-content-between">
				<div className="left-button">
					{/* BALANCE */}
					<Dropdown as={ButtonGroup} className="mr-2">
						<Button
							variant="info"
							onClick={(e) => {
								if (token) {
									setStage(STAGES.BALANCE)
								}
							}}
						>
							Balance
							{token && <span className="text-uppercase ml-2 text-warning">{`(${token.symbol})`}</span>}
						</Button>

						<Dropdown.Toggle split variant="info" id="dropdown-split-basic" />
						<Dropdown.Menu>
							{BSC_TOKENS.map((token, index) => (
								<div key={token.symbol + index}>
									<Dropdown.Item
										onClick={(e) => {
											setToken(token)
										}}
									>
										{token.symbol}
									</Dropdown.Item>
									{index === 0 && <Dropdown.Divider />}
								</div>
							))}
						</Dropdown.Menu>
					</Dropdown>

					{/* TRANSACTION_WALLET */}
					<Dropdown as={ButtonGroup} className="mr-2">
						<Button
							variant="success"
							onClick={(e) => {
								if (token) {
									setStage(STAGES.TRANSACTION_WALLET)
								}
							}}
						>
							Gom
							{token && <span className="text-uppercase ml-2 text-warning">{`(${token.symbol})`}</span>}
						</Button>

						<Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

						<Dropdown.Menu>
							{BSC_TOKENS.map((token, index) => (
								<div key={token.symbol + index}>
									<Dropdown.Item
										key={index}
										onClick={(e) => {
											setToken(token)
										}}
									>
										{token.symbol}
									</Dropdown.Item>
									{index === 0 && <Dropdown.Divider />}
								</div>
							))}
						</Dropdown.Menu>
					</Dropdown>

					{/* SPREAD_WALLET */}
					<Dropdown as={ButtonGroup} className="mr-2">
						<Button
							variant="warning"
							onClick={(e) => {
								if (token) {
									setStage(STAGES.SPREAD_WALLET)
								}
							}}
						>
							<FaMenorah /> Rải
							{token && <span className="text-uppercase ml-2 text-white">{`(${token.symbol})`}</span>}
						</Button>

						<Dropdown.Toggle split variant="warning" id="dropdown-split-basic" />

						<Dropdown.Menu>
							{BSC_TOKENS.map((token, index) => (
								<div key={token.symbol + index}>
									<Dropdown.Item
										key={index}
										onClick={(e) => {
											setToken(token)
										}}
									>
										{token.symbol}
									</Dropdown.Item>
									{index === 0 && <Dropdown.Divider />}
								</div>
							))}
						</Dropdown.Menu>
					</Dropdown>

					{showResult && (
						<button
							className="btn btn-warning ml-2"
							onClick={(e) => {
								setStage(STAGES.RESULT)
								setShowResult(false)
								setShowInput(true)
							}}
						>
							<FaWallet className="mr-1" /> Kết quả trước
						</button>
					)}
					{showInput && (
						<button
							className="btn btn-warning ml-2"
							onClick={(e) => {
								setStage(STAGES.DATA_TABLE)
								setShowResult(true)
								setShowInput(false)
							}}
						>
							<FaWallet className="mr-1" /> Danh sách CSV
						</button>
					)}
				</div>

				<div className="right-button">
					{/* Downloader */}
					<button className="btn btn-primary" title="Tải về">
						<CSVDownloader data={jsonToCSV(dataDownload)} filename="danhsachvi">
							<FaFileDownload />
						</CSVDownloader>
					</button>
					{/* Delete */}
					<button className="btn btn-danger text-white ml-2 self-align-end" onClick={handleOnRemoveFile} title="Xoá file">
						<FaTimes />
					</button>
				</div>
			</div>

			<hr className="my-3" />

			<div className="datatable-container" style={{ overflowX: "scroll", maxWidth: "100%", fontSize: "0.85rem" }}>
				{stage === STAGES.RESULT_BALANCE && <Table columns={Balance_Columns} rows={walletList} />}
				{stage === STAGES.DATA_TABLE && <Table columns={File_Columns} rows={walletList} />}
				{stage === STAGES.RESULT && <Table columns={Result_Columns} rows={walletList} />}
			</div>
		</>
	)
}
