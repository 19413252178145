// Tron 
export const FULLHOST_URL = 'https://api.trongrid.io'
export const FULLHOST_TESTNET_URL = 'https://api.shasta.trongrid.io'
export const TRONGRID_API_KEY = 'ebe26146-e69f-4e1c-9db0-c6f314b462f2'
export const TRON_SCAN_MAINNET = 'https://tronscan.org/#/transaction/'
export const TRON_SCAN_TESTNET = 'https://shasta.tronscan.org/#/transaction/'

// Rules (by TRX)
export const balanceTRX_limit = 5 // (minimum balance of TRX)
export const amount_min_limit = 0 // (minimum amount for transfer)
export const max_feeLimit = 100 // (maximum fee limit for contract transaction)

// TOKEN TRC20
export const TOKENS = [
  {symbol: "USDT", address: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t'},
  {symbol: "EUSD", address: 'TNUN6pFXEH3p3jhDZaTro6gsLCZ5fT3rqs'},
  {symbol: "DPC", address: 'TThWu2pSvDipigBGeeDSKsBC5emT5vLHPH'},
  {symbol: "FXT", address: 'TSfKrVkRCq3WsV71U4Zd1HLECMrBMEVtNF'},
]