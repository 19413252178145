import { Row, Col, Card, Badge } from "react-bootstrap"
import { useState, useEffect } from "react"
import { useGlobalContext } from "../context/global"

import Loading from "../components/Loading"
import FileDrop from "../components/Stage/FileDrop/FileDrop"
import BalanceTable from '../components/Stage/DataTable/BalanceTable'
import BalanceLogger from '../components/Stage/Logger/BalanceLogger'
import { useEthereumContext } from "../context/ethereum"
import { useTronContext } from "../context/tron"

const LOCAL_STORAGE_NAME = "BALANCE-Wallets"

export default function Balance() {
  const { isLoading, setIsLoading, currentPath, BALANCE_STAGES } = useGlobalContext()
  const {web3, getBalanceETH} = useEthereumContext()
  const {tronWeb, getBalanceTRX} = useTronContext()
  const [stage, setStage] = useState(BALANCE_STAGES.DROP_FILE)
  
  useEffect(() => {
    setIsLoading(false)
    const walletList = localStorage.getItem(LOCAL_STORAGE_NAME)
    if(walletList) {
      setStage(BALANCE_STAGES.DATA_TABLE)
    } else {
      setStage(BALANCE_STAGES.DROP_FILE)
    }
  }, [])
  
  // Get Balance from both Ethereum and Tron
  const getBalance = async function(address) {
    try {
      const prefixChar = (address) ? address.toString().substring(0, 1) : ''
      
      if (prefixChar === 'T') {
        // Tron wallet
        const {balanceTRX} = await getBalanceTRX(address)
        
        return {balance: tronWeb.fromSun(balanceTRX), unit: "TRX"}
      } else if (prefixChar === '0') {
        // Ethereum wallet
        const {balanceETH, error} = await getBalanceETH(address)
        return {balance: web3.utils.fromWei(balanceETH.toString(), 'ether'), unit: "ETH"}
      } 

      return {balance: ""}
    } catch (error) {
      return {balance: "", error}
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
    <div className="px-1 mx-3">
        <Row className="my-3">
          <Col>
            <Card>
              <h5 className="card-header ">
                Lấy <strong>{currentPath.toUpperCase()}</strong><Badge variant="primary ml-2">(TRX / ETH)</Badge>
              </h5>
              <div className="card-body p-3">
                {/* Stage 1 */}
                {stage === BALANCE_STAGES.DROP_FILE && (
                  <FileDrop
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    STAGES={BALANCE_STAGES}
                    setStage={setStage}
                  />
                )}

                {/* Stage 2 */}
                {(stage === BALANCE_STAGES.DATA_TABLE || stage === BALANCE_STAGES.RESULT) && (
                  <BalanceTable 
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME} 
                    setStage={setStage} 
                    showBalanceBtn={true}
                    setIsLoading={setIsLoading}
                    stage={stage}
                    />
                )}

                {/* Stage 3 */}
                {stage === BALANCE_STAGES.LOGGER && (
                  <BalanceLogger
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME} 
                    setStage={setStage}
                    getBalance={getBalance}
                     />
                )}

              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
