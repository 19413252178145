import { Row, Col, Card } from "react-bootstrap"
import { useState, useEffect } from "react"
import { useGlobalContext } from "../context/global"
import { useEthereumContext } from "../context/ethereum"
import Loading from "../components/Loading"
import FileDrop from "../components/Stage/FileDrop/FileDrop"
import MainWallet from "../components/Stage/Wallet/MainWallet"
import SpreadWallet from "../components/Stage/Wallet/SpreadWallet"
import ETHTable from "../components/Stage/DataTable/ETHTable"
import ETHLogger from "../components/Stage/Logger/ETHLogger"

const LOCAL_STORAGE_NAME = "ETH-Wallets"

export default function ETH() {
  const { isLoading, setIsLoading, currentPath, ETH_STAGES } = useGlobalContext()
  const {
    web3,
    getBalanceETH,
    sendETH,
    checkAddresses,
    calculateGasFeeETH,
    checkTransferETH,
    spreadETH,
  } = useEthereumContext()
  const [recipientAddr, setRecipientAddr] = useState("")
  const [stage, setStage] = useState(ETH_STAGES.DROP_FILE)
  const [spreaderAddr, setSpreaderAddr] = useState("")
  const [spreaderPK, setSpreaderPK] = useState("")
  const [amountToSpread, setAmountToSpread] = useState(0.0000)

  useEffect(() => {
    setIsLoading(false)
    const walletList = localStorage.getItem(LOCAL_STORAGE_NAME)
    if (walletList) {
      setStage(ETH_STAGES.DATA_TABLE)
    } else {
      setStage(ETH_STAGES.DROP_FILE)
    }
  }, [])

  // Check main wallet
  const checkMainWallet = function (address) {
    address = address.toString().trim()
    if (web3.utils.isAddress(address)) {
      return [true, null]
    }

    return [null, false]
  }

  // Validation clone wallets from file
  const validation = async function (wallet) {
    try {
      // Trim data
      wallet.address = wallet.address.toString().trim()
      wallet.privateKey = wallet.privateKey.toString().trim()

      // Check address format
      const { error: addressError } = await checkAddresses(wallet.address, recipientAddr)
      if (addressError) {
        return { ...wallet, error: addressError }
      }

      // Get balanceETH
      const { balanceETH, error: getBLError } = await getBalanceETH(wallet.address)
      if (getBLError) {
        return { ...wallet, error: getBLError }
      }
      wallet.balanceETH = balanceETH

      // Convert amount
      if (!wallet.amount) {
        wallet.amountETH = balanceETH // in wei
        wallet.amount = web3.utils.fromWei(balanceETH.toString(), "ether")
      } else {
        wallet.amountETH = web3.utils.toWei(wallet.amount.toString(), "ether")
      }

      // Check transfer ETH (amount, balanceETH)
      const { error: transferEthErr } = await checkTransferETH(
        recipientAddr,
        balanceETH,
        wallet.amountETH
      )
      if (transferEthErr) {
        return { ...wallet, error: transferEthErr }
      }

      // Calculate gas
      let { gasFee, error } = await calculateGasFeeETH(recipientAddr)
      if (error) {
        return { ...wallet, error: `[${error}]` }
      }

      // Reject if (gas >= amount)
      if (gasFee >= wallet.amountETH) {
        return {
          ...wallet,
          error: `[Gas fee too high! ${web3.utils.fromWei(gasFee.toString(), "ether")}]`,
        }
      }

      return {
        validWallet: {
          ...wallet,
          // balanceETH,
        },
      }
    } catch (error) {
      return { error: error.message }
    }
  }

  // Get balance (ETH)
  const getBalance = async function (address) {
    try {
      // Trim data
      address = address.toString().trim()
      const { balanceETH } = await getBalanceETH(address)
      return { balanceETH: web3.utils.fromWei(balanceETH, "ether") }
    } catch (error) {
      return { error }
    }
  }

  // Collect ETH
  const collect = async function (wallet, setMessage) {
    try {
      const { validWallet, error } = await validation(wallet)

      // If input amount === "" => `validation(wallet)` already convert to [wei]

      if (error) {
        setMessage(`Collecting from ${wallet.address}... ======> Error: ${error}`)
        return {
          ...wallet,
          error,
        }
      }

      // Collect ETH from sub wallets
      const { txHash, error: txHashError } = await sendETH(
        validWallet,
        recipientAddr,
        validWallet.amountETH
      )
      if (error) {
        setMessage(`Collecting from ${wallet.address}... ======> Error: ${txHashError}`)
      }

      if (txHash) {
        setMessage(`Collecting from ${validWallet.address}... ======> DONE [${txHash}]`)
      }
      return {
        wallet: {
          ...validWallet,
          txHash,
          // amount: wallet.amount,
          error: txHashError,
        },
      }
    } catch (error) {
      return { wallet, error: error.message }
    }
  }

  // Validation spread wallet
  const checkSpreadWallet = async function () {
    try {
      // Check address
      const isValidAddress = web3.utils.isAddress(spreaderAddr)
      if (!isValidAddress) {
        return [null, `Invalid format [${spreaderAddr}]`]
      }

      // Get balance ETH
      const {balanceETH, error: balETH_Err} = await getBalanceETH(spreaderAddr)
      if (balETH_Err) {
        return [null, balETH_Err]
      }

      // Check balance ETH ( !== 0 )
      if (parseInt(balanceETH) === 0) {
        return [null, `Empty balance (0 ETH)`]
      }

      // Check amountToSpread !== 0
      if (parseFloat(amountToSpread) === 0) {
        return [null, `Amount to spread must be at least 1`]
      }

      // Check gas fees higher than amount
      const {gasFee} = await calculateGasFeeETH(spreaderAddr)
      if (parseInt(web3.utils.toWei(amountToSpread.toString(), 'ether')) <= parseInt(gasFee.toString())) {
        return [null, `Gas too high! [${web3.utils.fromWei(gasFee.toString(), 'ether')}]`]
      }

      // Make sure enough for 1 spread
      const amount = parseInt(web3.utils.toWei(amountToSpread.toString(), 'ether')) + parseInt(gasFee)
      if (parseInt(amount) > parseInt(balanceETH)){
        return [null, `Insufficient balance ETH - Cost: ${web3.utils.fromWei(amount.toString(), 'ether')}`]
      }

      return [true, null]
    } catch (error) {
      return [null, error.message ? error.message : error]
    }
  }

  // Validation for Spreader each sending ETH
  const validationSpreader = async function(toAddress) {
    try {
      // Trim data
      toAddress = toAddress.toString().trim()

      // Check format address
      if (!web3.utils.isAddress(toAddress)) {
        return {error: `Invalid format [${toAddress}]`}
      }

      // Check same address
      if (toAddress === spreaderAddr) {
        return {error: `Same address [${toAddress}]`}
      }

      // Get balance
      const {balanceETH, error: balETH_Err} = await getBalanceETH(spreaderAddr)
      if (balETH_Err) {
        return {error: balETH_Err}
      }

      // Check balance !== 0
      if (parseInt(balanceETH) === 0) {
        return {error: `Empty balance (ETH)`}
      }

      // Check gas fees higher than amount
      const {gasFee} = await calculateGasFeeETH(spreaderAddr)
      if (parseInt(web3.utils.toWei(amountToSpread.toString(), 'ether')) <= parseInt(gasFee.toString())) {
        return {error: `Gas too high! [${web3.utils.fromWei(gasFee.toString(), 'ether')}]`}
      }
      
      // Make sure enough for 1 spread
      const amount = parseInt(web3.utils.toWei(amountToSpread.toString(), 'ether')) + parseInt(gasFee)

      // Check balance ETH >= amountToSpread
      if (parseInt(amount) > parseInt(balanceETH)){
        return {error: `Insufficient balance ETH - Cost: ${web3.utils.fromWei(amount.toString(), 'ether')}`}
      }

      
      return {isValid: true}
    } catch (error) {
      return {error: error.message}
    }
  }

  // Spread ETH
  const spread = async function (wallet, setMessage, index) {
    try {
      const { error } = await validationSpreader(wallet.address)
      if (error) {
        setMessage(`Spreading [${amountToSpread} ETH] to ${wallet.address}... ======>  ${error}  `)
        return { wallet, error }
      }

      const spreadAmountETH = web3.utils.toWei(amountToSpread.toString(), 'ether')

      // Collect ETH from sub wallets
      const { txHash, error: txHashError } = await spreadETH(
        { address: spreaderAddr, privateKey: spreaderPK },
        wallet.address,
        parseInt(spreadAmountETH),
        index
      )
      if (txHashError) {
        setMessage(
          `Spreading [${amountToSpread} ETH] to ${wallet.address}... ======> Error: ${txHashError}`)
      }

      if (txHash) {
        setMessage(`Spreading [${amountToSpread} ETH] to ${wallet.address}... ======> DONE [${txHash}]`)
      }

      return {
        wallet: {
          ...wallet,
          txHash,
          amount: parseFloat(amountToSpread),
          error: txHashError,
        },
      }
    } catch (error) {
      return { wallet, error: error.message }
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className="px-1 mx-3">
        <Row className="my-3">
          <Col>
            <Card>
              <h5 className="card-header">
                Đồng <strong>{currentPath.toUpperCase()}</strong>
              </h5>
              <div className="card-body p-3">
                {/* Stage 1 */}
                {stage === ETH_STAGES.DROP_FILE && (
                  <FileDrop
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    STAGES={ETH_STAGES}
                    setStage={setStage}
                  />
                )}

                {/* Stage 2 || 5 || 7 */}
                {(stage === ETH_STAGES.DATA_TABLE ||
                  stage === ETH_STAGES.RESULT ||
                  stage === ETH_STAGES.RESULT_BALANCE) && (
                  <ETHTable
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    setStage={setStage}
                    stage={stage}
                  />
                )}

                {/* Stage 3  */}
                {stage === ETH_STAGES.TRANSACTION_WALLET && (
                  <MainWallet
                    setStage={setStage}
                    recipientAddr={recipientAddr}
                    setRecipientAddr={setRecipientAddr}
                    STAGES={ETH_STAGES}
                    checkMainWallet={checkMainWallet}
                  />
                )}

                {/* Stage 4  */}
                {stage === ETH_STAGES.SPREAD_WALLET && (
                  <SpreadWallet
                    setStage={setStage}
                    STAGES={ETH_STAGES}
                    spreaderAddr={spreaderAddr}
                    setSpreaderAddr={setSpreaderAddr}
                    spreaderPK={spreaderPK}
                    setSpreaderPK={setSpreaderPK}
                    validateSpreadWallet={checkSpreadWallet}
                    amountToSpread={amountToSpread}
                    setAmountToSpread={setAmountToSpread}
                    nextStage={ETH_STAGES.SPREAD_LOGGER}
                    prevStage={ETH_STAGES.DATA_TABLE}
                  />
                )}

                {/* Stage 4 */}
                {(stage === ETH_STAGES.LOGGER 
                || stage === ETH_STAGES.BALANCE
                || stage === ETH_STAGES.SPREAD_LOGGER) && (
                  <ETHLogger
                    LOCAL_STORAGE_NAME={LOCAL_STORAGE_NAME}
                    setStage={setStage}
                    stage={stage}
                    getBalance={getBalance}
                    collect={collect}
                    spread={spread}
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
