import { Form, FormLabel, FormGroup, FormControl } from "react-bootstrap"
import { FaArrowLeft, FaWallet, FaKey, FaCoins } from "react-icons/fa"

import { useState, useEffect } from "react"
import { useGlobalContext } from "../../../context/global"

export default function Wallet({
  setStage,
  spreaderAddr,
  setSpreaderAddr,
  spreaderPK,
  setSpreaderPK,
  validateRandomSpreadWallet,
  token,
  minRandomNumb, 
  setMinRandomNumb,
  maxRandomNumb, 
  setMaxRandomNumb,
  randomWithAccumulate,
  setRandomWithAccumulate,
  nextStage,
  prevStage,
}) {
  const { setIsLoading } = useGlobalContext()
  const [isChecking, setIsChecking] = useState(false)
  const [error, setError] = useState("")

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const handleSubmit = function (e) {
    e.preventDefault()
    setIsChecking(true)
    
    // const {maxRandomNumb, minRandomNumb} = e.target.elements
    // setMinRandomNumb(minRandomNumb.value)
    // setMaxRandomNumb(maxRandomNumb.value)
    
    validateRandomSpreadWallet().then((result) => {
        const { error } = result
        if (error) {
          setError(error)
          setIsChecking(false)
          return
        }
        
        setIsChecking(false)
        setStage(nextStage)
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <small>
        <button className="btn btn-secondary py-1 px-2" onClick={(e) => setStage(prevStage)}>
          <FaArrowLeft style={{ fontSize: "13px" }} />
        </button>
      </small>
      <Form onSubmit={handleSubmit} className="mt-4">
        {/* Address */}
        <FormGroup>
          <FormLabel>
            <FaWallet className="mr-2" />
            Địa chỉ ví tổng:
          </FormLabel>
          <FormControl
            type="text"
            required
            name="walletAddress"
            onChange={(e) => setSpreaderAddr(e.target.value.toString().trim())}
            value={spreaderAddr}
          />
        </FormGroup>
        {/* PK */}
        <FormGroup>
          <FormLabel>
            <FaKey className="mr-2" />
            PK ví tổng:
          </FormLabel>
          <FormControl
            type="text"
            required
            name="walletPK"
            onChange={(e) => setSpreaderPK(e.target.value.toString().trim())}
            value={spreaderPK}
          />
        </FormGroup>
        {/* Random accumulate */}
        <Form.Group>
          <Form.Label>
            <FaCoins className="mr-2 my-1"/>Ngẫu nhiên
          </Form.Label>
          <Form.Check
            name="accumulateRandom"
            type="checkbox" 
            label="Cộng dồn tích luỹ" 
            checked={randomWithAccumulate ? 'checked' : ''}
            onChange={ e => setRandomWithAccumulate(e.target.value)}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label><FaCoins className="mr-2"/>Min</Form.Label>
          <Form.Control 
            name="minRandomNumb"
            type="number" 
            value={minRandomNumb}
            step={1}
            onChange={e => setMinRandomNumb(parseFloat(e.target.value))}
            min={minRandomNumb}
            >
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="exampleForm.SelectCustom">
          <Form.Label><FaCoins className="mr-2"/>Max</Form.Label>
          <Form.Control 
            name ="maxRandomNumb"
            type="number" 
            value={maxRandomNumb}
            step={1}
            onChange={e => setMaxRandomNumb(parseFloat(e.target.value))}
            min={maxRandomNumb}
            >
          </Form.Control>
        </Form.Group>
        {/* Alert message */}
        <FormGroup>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </FormGroup>
        {/* Loader */}
        {isChecking && (
          <div className="text-center my-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        )}

        <FormGroup>
          <FormControl
            className="btn btn-primary"
            type="submit"
            value="Send"
            disabled={isChecking ? "disabled" : ""}
          />
        </FormGroup>
      </Form>
    </>
  )
}
