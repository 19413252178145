import { useState, useEffect, useRef } from "react"
import { useGlobalContext } from "../../../context/global"

import Logger from "./Logger"

export default function BalanceLogger({ LOCAL_STORAGE_NAME, getBalance, setStage }) {
  const {BALANCE_STAGES} = useGlobalContext()
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState("")
  const [done, setDone] = useState(false)
  const [walletList] = useState(() => {
    const localStrData = localStorage.getItem(LOCAL_STORAGE_NAME)
    const walletList =JSON.parse(localStrData)
    walletList.splice(0,1)
    if (localStrData) {
      const wallets = walletList.map((item) => {
        return { address: item.data[0], privateKey: item.data[1], amount: item.data[2] }
      })

      return wallets
    }

    return []
  })
  const LoggerDiv = useRef()

  useEffect(() => {
    async function getBalances() {
      const walletsWithBal = Promise.all(
        walletList.map(async (wallet, index) => {
          return new Promise((resolve) =>
            setTimeout(async () => {
              const { balance, unit } = await getBalance(wallet.address)
              
              setMessage(`${wallet.address} --- ${balance} ~ ${unit}`)
              return resolve({ ...wallet, balance, unit })
            }, index * 700)
          )
        })
      )

      return walletsWithBal
    }

    // Save to localStorage
    getBalances().then((result) => {
      localStorage.setItem(`${LOCAL_STORAGE_NAME}-result`, JSON.stringify(result))
      setDone(true)
      setStage(BALANCE_STAGES.RESULT)
    })
  }, [])

  // Append message
  useEffect(() => {
    if (message)  {
      setMessages([...messages, message])
      const el = LoggerDiv.current
      el.scrollTop = el.scrollHeight
    }
  }, [message])

  return (
    <div style={{ maxHeight: "80vh", overflowY: "scroll" }} ref={LoggerDiv}>
      {!done && (
        <>
          <Logger messages={messages} />
          <div className="text-center">
            <div
              className="spinner-border"
              role="status"
              style={{ width: "3rem", height: "3rem" }}
            ></div>
          </div>
        </>
      )}
    </div>
  )
}
