import { CSVDownloader, jsonToCSV } from "react-papaparse"
import { useState, useEffect } from "react"
import {Dropdown, ButtonGroup, Button} from 'react-bootstrap'
import { FaFileDownload, FaTimes, FaMenorah, FaRandom, FaWallet } from "react-icons/fa"
import Table from "../../Table/Table"
import { useGlobalContext } from "../../../context/global"
import { TOKENS as TRON_TOKENS } from "../../../constant/tron"
import {File_Columns, Result_Columns} from '../Columns/TronWallletsCol'
import Loading from "../../Loading"

export default function TronWallets({ 
  LOCAL_STORAGE_NAME, stage, setStage, isActive
 }) {
  const [isLoading, setIsLoading] = useState(true)
  const { WALLETS_STAGES, } = useGlobalContext()
  const [error, setError] = useState("")
  const [showResult, setShowResult] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [walletList, setWalletList] = useState([])
  const [dataDownload, setDataDownload] = useState("[]")
  const [timeoutBtn, setTimeoutBtn] = useState(0)

  useEffect(() => {
    if (stage === WALLETS_STAGES.DATA_TABLE) {
      const localStrData = localStorage.getItem(`${LOCAL_STORAGE_NAME}`)
      if (localStrData) {
        setWalletList(JSON.parse(localStrData))
        setDataDownload(JSON.parse(localStrData))
        setIsLoading(false)
      }
    }

    if (stage === WALLETS_STAGES.RESULT) {
      const localStrData = localStorage.getItem(`${LOCAL_STORAGE_NAME}-result`)
      if (localStrData) {
        setWalletList(JSON.parse(localStrData))
        setDataDownload(JSON.parse(localStrData))
        setIsLoading(false)
        return
      }
    }
    if (stage === WALLETS_STAGES.RESULT_BALANCE) {
      const localStrData = localStorage.getItem(`${LOCAL_STORAGE_NAME}-balance`)
      if (localStrData) {
        setWalletList(JSON.parse(localStrData))
        setDataDownload(JSON.parse(localStrData))
        setIsLoading(false)
        return
      }
    }

  }, [])

  // Control buttons
  useEffect(() => {
    if (stage === WALLETS_STAGES.RESULT) {
      setShowInput(true)
      setShowResult(false)
    } else if (stage === WALLETS_STAGES.DATA_TABLE) {
      setShowInput(false)
      setShowResult(true)
    }
  }, [])

  const handleOnRemoveFile = (data) => {
    localStorage.removeItem(LOCAL_STORAGE_NAME)
    localStorage.removeItem(`${LOCAL_STORAGE_NAME}`)
    setWalletList([])
    setStage(WALLETS_STAGES.GENERATE)
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {error && <p className="text-danger">{error}</p>}
      <div className="d-flex justify-content-between">
        <div className="left-button">
          {/* ACTIVATE */}
          <button
            className="btn btn-success mr-2"
            onClick={(e) => {
              setTimeoutBtn(30)
              setTimeout(() => {
                e.target.disabled = 'disabled'
              }, timeoutBtn)
              setStage(WALLETS_STAGES.ACTIVATE_WALLET)
            }}
          >
            <FaRandom /> Kích hoạt {timeoutBtn !== 0 && (<span>{`(${timeoutBtn})`}</span>)}
          </button>
        </div>

        <div className="right-button">
          {/* Downloader */}
          <button className="btn btn-primary" title="Tải về">
            <CSVDownloader data={jsonToCSV(dataDownload)} filename="danhsachvi">
              <FaFileDownload />
            </CSVDownloader>
          </button>
          {/* Delete */}
          <button
            className="btn btn-danger text-white ml-2 self-align-end"
            onClick={handleOnRemoveFile}
            title="Xoá file"
          >
            <FaTimes />
          </button>
        </div>
      </div>

      <hr className="my-3" />

      <div className="datatable-container" style={{ overflowX: "scroll", maxWidth: "100%", fontSize: "0.85rem" }}>
        {stage === WALLETS_STAGES.DATA_TABLE && <Table columns={File_Columns} rows={walletList} />}
        {stage === WALLETS_STAGES.RESULT && <Table columns={Result_Columns} rows={walletList} />}
       </div>

    </>
  )
}
