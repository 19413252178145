import { types } from 'mobx-state-tree'
import { BscModel, initBscModel } from './BSC'

const RootModel = types.model({
	BscStore: BscModel,
})

export const useRootStore = () => {
	const RootStore = RootModel.create({
		...RootModel,
		BscStore: { ...initBscModel, NETWORK: 'MAIN' },
	})

	return { RootStore }
}
