import { CSVReader } from "react-papaparse"
import { useEffect, useState } from "react"

export default function FileDrop({ LOCAL_STORAGE_NAME, STAGES, setStage }) {
  const [dropped, setDropped] = useState(false)
  const [error, setError] = useState("")

  useEffect(() => {
    const wallets = localStorage.getItem(LOCAL_STORAGE_NAME)

    if (wallets) {
      setDropped(true)
      setStage(STAGES.DATA_TABLE)
    }
    
  }, [])

  const fileFilter = (fileName) => {
    const fileExtension = fileName.toString().split(".")[1]
    if (fileExtension === "csv") {
      return true
    }

    return false
  }

  const handleOnDrop = (data, file) => {
    if (fileFilter(file.name)) {
      localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(data))
      setError("")
      setDropped(true)
      setStage(STAGES.DATA_TABLE)
    } else { 
      setError("Chỉ nhận file định dạng .csv")
    }
  }

  const handleOnError = (err, file, inputElem, reason) => {
    setError(err)
  }

  const handleOnRemoveFile = (data) => {
    localStorage.removeItem(LOCAL_STORAGE_NAME)
    setError("")
    setDropped(false)
  }

  return (
    <>
      {/* Drop file */}
      <div className="drop-file">
        {error && <p className="text-danger">{error}</p>}

        {!dropped && (
          <div className="CSVReader" style={{ height: "50vh" }}>
            <CSVReader
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}
              skipEmptyLines={true}
            >
              <span>
                <strong>
                  Kéo và thả file <span className="text-danger">csv</span> tại đây
                </strong>
              </span>
            </CSVReader>
          </div>
        )} 
      </div>
    </>
  )
}
