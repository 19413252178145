import { Form, FormLabel, FormGroup, FormControl } from "react-bootstrap"
import { useState, useEffect } from "react"
import { useGlobalContext } from "../../../context/global"
import { FaArrowLeft, FaWallet, FaKey } from "react-icons/fa"

export default function Wallet({
  stage,
  setStage,
  recipientAddr,
  setRecipientAddr,
  providerAddr,
  setProviderAddr,
  providerPK,
  setProviderPK,
  token,
  checkMainWallet,
  validateProvider,
}) {
  const { setIsLoading, TRC20_STAGES } = useGlobalContext()
  const [isChecking, setIsChecking] = useState(false)
  const [error, setError] = useState("")
  const [walletBalance, setWalletBalance] = useState("")
  const [walletBalanceLimit, setWalletBalanceLimit] = useState("")

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const handleSubmit = function (e) {
    e.preventDefault()
    setIsChecking(true)
    const { mainAddress, walletAddress, walletPK } = e.target.elements

    checkMainWallet(mainAddress.value)

    validateProvider()
      .then((result) => {
        const { error } = result

        if (error) {
          setError(error)
          setIsChecking(false)
        }

        // Valid
        setIsChecking(false)
        setStage(TRC20_STAGES.LOGGER)
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <small>
        <button className="btn btn-secondary py-1 px-2" onClick={(e) => setStage(TRC20_STAGES.DATA_TABLE)}>
          <FaArrowLeft style={{ fontSize: "13px" }} />
        </button>
      </small>
      <Form onSubmit={handleSubmit} className="mt-4">
        <FormGroup>
          <FormLabel>
            <FaWallet className="mr-2" />
            Địa chỉ ví tổng:
          </FormLabel>
          <FormControl
            type="text"
            required
            name="mainAddress"
            onChange={(e) => setRecipientAddr(e.target.value.toString().trim())}
            value={recipientAddr}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>
            <FaWallet className="mr-2" />
            Địa chỉ ví cấp phí:
          </FormLabel>
          <FormControl
            type="text"
            required
            name="walletAddress"
            onChange={(e) => setProviderAddr(e.target.value.toString().trim())}
            value={providerAddr}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>
            <FaKey className="mr-2" />
            PK ví cấp:
          </FormLabel>
          <FormControl
            type="text"
            required
            name="walletPK"
            onChange={(e) => setProviderPK(e.target.value.toString().trim())}
            value={providerPK}
          />
        </FormGroup>
        {/* Alert message */}
        <FormGroup>
          {walletBalance && (
            <ul>
              <li>
                Balance --- {walletBalance} [{token.symbol}]
                {walletBalanceLimit && (
                  <span className="ml-2 text-danger">
                    (Required: {walletBalanceLimit} [{token.symbol}])
                  </span>
                )}
              </li>
            </ul>
          )}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </FormGroup>

        {/* Loader */}
        {isChecking && (
          <div className="text-center my-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        )}

        <FormGroup>
          <FormControl
            className="btn btn-primary"
            type="submit"
            value="Send"
            disabled={isChecking ? "disabled" : ""}
          />
        </FormGroup>
      </Form>
    </>
  )
}
