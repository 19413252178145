import { useState, useEffect, useRef } from "react"
import { useEthereumContext } from "../../../context/ethereum"
import { useGlobalContext } from "../../../context/global"
import Logger from "./Logger"

export default function ERC20Logger({
  LOCAL_STORAGE_NAME,
  setStage,
  stage,
  getBalance,
  token,
  collect,
  spread,
}) {
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState("")
  const [done, setDone] = useState(false)
  const [walletList] = useState(() => {
    const localStrData = localStorage.getItem(LOCAL_STORAGE_NAME)
    const walletList = JSON.parse(localStrData)
    walletList.splice(0, 1)
    if (localStrData) {
      const wallets = walletList.map((item) => {
        return { address: item.data[0], privateKey: item.data[1], amount: item.data[2] }
      })

      return wallets
    }

    return []
  })
  const { ERC20_STAGES } = useGlobalContext()
  const { web3 } = useEthereumContext()
  const LoggerDiv = useRef()

  // Append message
  useEffect(() => {
    if (message) {
      setMessages([...messages, message])
      const el = LoggerDiv.current
      el.scrollTop = el.scrollHeight
    }
  }, [message])

  useEffect(() => {
    // ======COLLECT [ERC20]
    if (stage === ERC20_STAGES.LOGGER) {
      async function collectERC20() {
        const collectings = Promise.all(
          walletList.map(async (wallet, index) => {
            return new Promise((resolve) =>
              // collect(wallet, setMessage)
              //   .then((r) => {
              //     const w = { ...r }
              //     w.balanceETH =
              //       w.balanceETH && web3.utils.fromWei(w.balanceETH.toString(), "ether")
              //     w.gasFee = w.gasFee && web3.utils.fromWei(w.gasFee.toString(), "ether")
              //     return resolve(w)
              //   })
              //   .catch((e) => resolve(e))
              setTimeout(async () => {
                const w = collect(wallet, setMessage)
                if (w.error) {
                  setMessage(`${wallet.address} --- ${w.error}`)
                  return resolve({ ...wallet, error: w.error })
                }

                w.balanceETH =
                  w.balanceETH && web3.utils.fromWei(w.balanceETH.toString(), "ether")
                w.gasFee = w.gasFee && web3.utils.fromWei(w.gasFee.toString(), "ether")
                return resolve(w)
              }, 10 * index)
            )
          })
        )

        return collectings
      }

      // Save to localStorage
      collectERC20().then((result) => {
        localStorage.setItem(`${LOCAL_STORAGE_NAME}-result`, JSON.stringify(result))
        setTimeout(() => {
          setDone(true)
          setStage(ERC20_STAGES.RESULT)
        }, 5000)
      })
    }

    // ======GET BALANCE [ERC20]
    if (stage === ERC20_STAGES.BALANCE) {
      async function getBalances() {
        const walletsWithBal = Promise.all(
          walletList.map(async (wallet, index) => {
            return new Promise(async (resolve) => {
              setTimeout(async () => {
                const { balanceERC20, error } = await getBalance(wallet.address)
                if (error) {
                  setMessage(`${wallet.address} --- Failed to get balance`)
                  return resolve({ ...wallet, error })
                }
                // Done
                setMessage(`${wallet.address} --- ${balanceERC20} ~ ${token.symbol}`)
                return resolve({ ...wallet, balanceERC20 })
              }, 100 * index)
            })
          })
        )

        return walletsWithBal
      }

      // Save to localStorage
      getBalances().then((result) => {
        localStorage.setItem(`${LOCAL_STORAGE_NAME}-balance`, JSON.stringify(result))
        setDone(true)
        setStage(ERC20_STAGES.RESULT_BALANCE)
      })
    }

    // ======SPREAD [ERC20]
    if (stage === ERC20_STAGES.SPREAD_LOGGER) {
      async function spreadERC20() {
        const collectings = Promise.all(
          walletList.map(async (wallet, index) => {
            return new Promise((resolve) =>
              // spread(wallet, setMessage, index)
              //   .then((r) => {
              //     const w = { ...r }
              //     w.balanceETH =
              //       w.balanceETH && web3.utils.fromWei(w.balanceETH.toString(), "ether")
              //     w.gasFee = w.gasFee && web3.utils.fromWei(w.gasFee.toString(), "ether")
              //     return resolve(w)
              //   })
              //   .catch((e) => resolve(e))
              setTimeout(async () => {
                const w = await spread(wallet, setMessage, index)
                if (w.error) {
                  setMessage(`${wallet.address} --- ${w.error}`)
                  return resolve({ ...wallet, error: w.error })
                }

                w.balanceETH =
                w.balanceETH && web3.utils.fromWei(w.balanceETH.toString(), "ether")
                w.gasFee = w.gasFee && web3.utils.fromWei(w.gasFee.toString(), "ether")
                return resolve(w)
              }, 10 * index)
            )
          })
        )

        return collectings
      }

      // Save to localStorage
      spreadERC20().then((result) => {
        localStorage.setItem(`${LOCAL_STORAGE_NAME}-result`, JSON.stringify(result))
        setTimeout(() => {
          setDone(true)
          setStage(ERC20_STAGES.RESULT)
        }, 5000)
      })
    }
  }, [])

  return (
    <div style={{ overflowY: "scroll", maxHeight: "80vh" }} ref={LoggerDiv}>
      {!done && (
        <>
          <Logger messages={messages} />
          <div className="text-center">
            <div
              className="spinner-border"
              role="status"
              style={{ width: "3rem", height: "3rem" }}
            ></div>
          </div>
        </>
      )}
    </div>
  )
}
