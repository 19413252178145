import { Container, Row, Col } from "react-bootstrap"
import { useGlobalContext } from "../context/global"

export default function Home() {
    const { RootStore } = useGlobalContext()

    return (
        <>
            <Container>
                <Row>
                    <Col
                        className="col-12 align-items-center justify-content-center "
                        style={{
                            height: "90vh",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div class="card">
                            <h3 class="card-header">Lưu ý</h3>
                            <div class="card-body">
                                {/* File CSV */}
                                <h5 class="card-title">File CSV</h5>
                                <ul>
                                    <li>
                                        Mỗi dòng phải bao gồm 3 cột thông tin:{" "}
                                        <strong>
                                            [địa chỉ ví], [key của ví], [số
                                            lượng cần gom]
                                        </strong>
                                        .
                                    </li>
                                    <li>
                                        Nếu cột{" "}
                                        <strong>[số lượng cần gom]</strong> bị
                                        bỏ trống thì mặc định sẽ gom hết số đồng
                                        trong ví.
                                    </li>
                                </ul>
                                {/* Giao dịch */}
                                <h5 class="card-title">Giao dịch</h5>
                                <ul>
                                    <li>
                                        <strong>Ví tổng</strong> cần phải có
                                        tiền điện tử để chuyển cho các ví con
                                        không đủ phí giao dịch.
                                    </li>
                                    <li>
                                        Trong mạng <strong>Tron</strong>, với
                                        yêu cầu chuyển đồng{" "}
                                        <strong>[TRC20]</strong>, ví con bắt
                                        buộc có tối thiểu{" "}
                                        <strong>[5 TRX]</strong> để tiến hành
                                        giao dịch.
                                    </li>
                                </ul>
                                {/* Kết quả */}
                                <h5 class="card-title">Kết quả</h5>
                                <ul>
                                    <li>
                                        Mỗi lần gom - rải được thực hiện cần
                                        thời gian để được xác nhận vào block của
                                        mạng Blockchain. Do đó khi lấy balance
                                        sau khi thực hiện gom - rải sẽ thấy
                                        không có thay đổi. Hãy thử lại sau vài
                                        giây.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
