import { CSVDownloader, jsonToCSV } from "react-papaparse"
import { useState, useEffect } from "react"
import { FaFileDownload, FaTimes, FaWallet, FaStackOverflow, FaMenorah } from "react-icons/fa"
import { ButtonGroup, Button, Dropdown } from "react-bootstrap"
import Table from "../../Table/Table"
import { useGlobalContext } from "../../../context/global"
import { TOKENS as TRON_TOKENS } from "../../../constant/tron"
import {Balance_Columns, Result_Columns, File_Columns} from '../Columns/TRC20Col'

export default function TRC20Table({ LOCAL_STORAGE_NAME, stage, setStage, token, setToken }) {
  const { TRC20_STAGES, validateInputCSV, parseResultDownload } = useGlobalContext()
  const [error, setError] = useState("")
  const [showResult, setShowResult] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [walletList, setWalletList] = useState([])
  const [dataDownload, setDataDownload] = useState(JSON.parse("[]"))

  useEffect(() => {
    if (stage === TRC20_STAGES.RESULT || TRC20_STAGES.RESULT_BALANCE) {
      setShowInput(true)
      setShowResult(false)
    } else if (stage === TRC20_STAGES.DATA_TABLE || stage === TRC20_STAGES.BALANCE) {
      setShowInput(false)
      setShowResult(true)
    }
  }, [])

  // Set data for table
  useEffect(() => {
    if (stage === TRC20_STAGES.DATA_TABLE) {
      const walletData = validateInputCSV(LOCAL_STORAGE_NAME, setError)
      if (walletData.length !== 0) {
        setWalletList(walletData)
        setDataDownload(walletData)
        return
      }
    }

    if (stage === TRC20_STAGES.RESULT) {
      const localStrData = localStorage.getItem(`${LOCAL_STORAGE_NAME}-result`)
      if (localStrData) {
        setWalletList(JSON.parse(localStrData))
        setDataDownload(parseResultDownload(JSON.parse(localStrData)))
        return
      }
    }

    if (stage === TRC20_STAGES.RESULT_BALANCE) {
      const localStrData = localStorage.getItem(`${LOCAL_STORAGE_NAME}-balance`)
      if (localStrData) {
        setWalletList(JSON.parse(localStrData))
        setDataDownload(JSON.parse(localStrData))
        return
      }
    }
  }, [stage])

  const handleOnRemoveFile = (data) => {
    localStorage.removeItem(LOCAL_STORAGE_NAME)
    localStorage.removeItem(`${LOCAL_STORAGE_NAME}-result`)
    setWalletList([])
    setStage(TRC20_STAGES.DROP_FILE)
  }

  return (
    <>
      {error && <p className="text-danger">{error}</p>}

      <div className="d-flex justify-content-between">
        <div className="left-button">
          {/* BALANCE */}
          <Dropdown as={ButtonGroup} className="mr-2">
            <Button
              variant="info"
              onClick={(e) => {
                if (token) {
                  setStage(TRC20_STAGES.BALANCE)
                }
              }}
            >
            <FaWallet className="mr-1" /> Balance
              {token && (
                <span className="text-uppercase ml-2 text-white">{`(${token.symbol})`}</span>
              )}
            </Button>

            <Dropdown.Toggle split variant="info" id="dropdown-split-basic" />
            <Dropdown.Menu>
              {TRON_TOKENS.map((token, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={(e) => {
                    setToken(token)
                  }}
                >
                  {token.symbol}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          {/* COLLECT */}
          <Dropdown as={ButtonGroup} className="mr-2">
            <Button
              variant="success"
              onClick={(e) => {
                if (token) {
                  setStage(TRC20_STAGES.TRANSACTION_WALLET)
                }
              }}
            >
            <FaStackOverflow /> Gom
              {token && (
                <span className="text-uppercase ml-2 text-white">{`(${token.symbol})`}</span>
              )}
            </Button>

            <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

            <Dropdown.Menu>
              {TRON_TOKENS.map((token, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={(e) => {
                    setToken(token)
                  }}
                >
                  {token.symbol}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          {/* SPREAD_WALLET */}
          <Dropdown as={ButtonGroup} className="mr-2">
            <Button
              variant="warning"
              onClick={(e) => {
                if (token) {
                  setStage(TRC20_STAGES.SPREAD_WALLET)
                }
              }}
            >
            <FaMenorah /> Rải
              {token && (
                <span className="text-uppercase ml-2 text-white">{`(${token.symbol})`}</span>
              )}
            </Button>

            <Dropdown.Toggle split variant="warning" id="dropdown-split-basic" />

            <Dropdown.Menu>
              {TRON_TOKENS.map((token, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={(e) => {
                    setToken(token)
                  }}
                >
                  {token.symbol}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          {/* RANDOM SPREAD */}
          <Dropdown as={ButtonGroup} className="mr-2">
            <Button
              variant="warning"
              onClick={(e) => {
                if (token) {
                  setStage(TRC20_STAGES.RANDOM_SPREAD_WALLET)
                }
              }}
            >
            <FaMenorah /> Rải ngẫu nhiên
              {token && (
                <span className="text-uppercase ml-2 text-white">{`(${token.symbol})`}</span>
              )}
            </Button>

            <Dropdown.Toggle split variant="warning" id="dropdown-split-basic" />

            <Dropdown.Menu>
              {TRON_TOKENS.map((token, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={(e) => {
                    setToken(token)
                  }}
                >
                  {token.symbol}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>


          {showResult && (
            <button
              className="btn btn-warning ml-2"
              onClick={(e) => {
                setStage(TRC20_STAGES.RESULT)
                setShowResult(false)
                setShowInput(true)
              }}
            >
              <FaWallet className="mr-1" /> Kết quả trước
            </button>
          )}
          {showInput && (
            <button
              className="btn btn-warning ml-2"
              onClick={(e) => {
                setStage(TRC20_STAGES.DATA_TABLE)
                setShowResult(true)
                setShowInput(false)
              }}
            >
              <FaWallet className="mr-1" /> Danh sách CSV
            </button>
          )}
        </div>

        <div className="right-button">
          {/* Downloader */}
          <button className="btn btn-primary" title="Tải về">
            <CSVDownloader data={jsonToCSV(dataDownload)} filename="danhsachvi">
              <FaFileDownload />
            </CSVDownloader>
          </button>
          {/* Delete */}
          <button
            className="btn btn-danger text-white ml-2 self-align-end"
            onClick={handleOnRemoveFile}
            title="Xoá file"
          >
            <FaTimes />
          </button>
        </div>
      </div>

      <hr className="my-3" />

      <div className="datatable-container" style={{ overflowX: "scroll", maxWidth: "100%", fontSize: "0.85rem" }}>
        {stage === TRC20_STAGES.RESULT_BALANCE && <Table columns={Balance_Columns} rows={walletList} />}
        {stage === TRC20_STAGES.DATA_TABLE && <Table columns={File_Columns} rows={walletList} />}
        {stage === TRC20_STAGES.RESULT && <Table columns={Result_Columns} rows={walletList} />}
      </div>
    </>
  )
}
