import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function Loading() {
  return (
    <Container>
      <Row>
        <Col
          className="col-12 align-items-center justify-content-center "
          style={{
            height: "90vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="spinner-border"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          ></div>
        </Col>
      </Row>
    </Container>
  )
}
